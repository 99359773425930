// [
//     {
//         title: "Membership",
//         "icon": "bi-person-badge-fill",
//         childrens: [
//             {
//                 title: "Members",
//                 "icon": "bi-people-fill",
//                 path: (tenantId) => `/${tenantId}/members`
//             },
//             {
//                 title: "Add Member",
//                 "icon": "bi-person-plus-fill",
//                 path: (tenantId) => `/${tenantId}/memberAdd`
//             },
//             {
//                 title: "Usage",
//                 "icon": "bi-activity",
//                 path: "/about"
//             }
//         ]
//     },
//     {
//         title: "Events",
//         "icon": "bi-calendar2-event-fill",
//         childrens: [
//             {
//                 title: "Calendar",
//                 "icon": "bi-calendar3",
//                 path: "/"
//             },
//             {
//                 title: "Add Event",
//                 "icon": "bi-calendar2-plus-fill",
//                 path: "/"
//             },
//             {
//                 title: "Event History",
//                 "icon": "bi-clock-history",
//                 path: "/"
//             }
//         ]
//     },
//     {
//         title: "Profile",
//         "icon": "bi-gear-fill",
//         childrens: [
//             {
//                 title: "Profile",
//                 path: "/profile"
//             },
//             {
//                 title: "Settings",
//                 childrens: [
//                     {
//                         title: "Account",
//                         path: "/settings/account"
//                     },
//                     {
//                         title: "Billing",
//                         childrens: [
//                             {
//                                 title: "Payment",
//                                 path: "/settings/billing/payment"
//                             },
//                             {
//                                 title: "Subscription",
//                                 path: "/settings/billing/subscription"
//                             }
//                         ]
//                     },
//                     {
//                         title: "Notifications",
//                         path: "/settings/notifications"
//                     }
//                 ]
//             },
//             {
//                 title: "Reset Password",
//                 path: "/reset-password"
//             },
//             {
//                 title: "Logout",
//                 path: "/logout"
//             }
//         ]
//     },
//     {
//         title: "General",
//         "icon": "bi-info-circle-fill",
//         childrens: [
//             {
//                 title: "Home",
//                 "icon": "bi-house-fill",
//                 path: "/"
//             },
//             {
//                 title: "About",
//                 "icon": "bi-info-circle-fill",
//                 path: "/about"
//             },
//             {
//                 title: "Contact",
//                 "icon": "bi-phone-fill",
//                 childrens: [
//                     {
//                         title: "Facebook",
//                         "icon": "bi-facebook"
//                     },
//                     {
//                         title: "Twitter",
//                         "icon": "bi-twitter"
//                     },
//                     {
//                         title: "Instagram",
//                         "icon": "bi-instagram"
//                     }
//                 ]
//             },
//             {
//                 title: "FAQ",
//                 "icon": "bi-question-circle-fill"
//             }
//         ]
//     },
//     {
//         title: "Support",
//         "icon": "bi-question-circle-fill",
//         path: "/support"
//     },
//     {
//         title: "Report Bug",
//         "icon": "bi-bug",
//         path: "/report-bug"
//     }
// ];

// Correct import statements (if any)
// const sidebar = [
//     {
//         title: "Membership",
//         icon: "bi-person-badge-fill",
//         childrens: [
//             {
//                 title: "Members",
//                 icon: "bi-people-fill",
//                 path: "/${tenantId}/members", // Template string for dynamic replacement
//             },
//             {
//                 title: "Add Member",
//                 icon: "bi-person-plus-fill",
//                 path: "/${tenantId}/memberAdd",
//             },
//             {
//                 title: "Usage",
//                 icon: "bi-activity",
//                 path: "/about",
//             },
//         ],
//     },
//     {
//         title: "Events",
//         icon: "bi-calendar2-event-fill",
//         childrens: [
//             {
//                 title: "Calendar",
//                 icon: "bi-calendar3",
//                 path: "/",
//             },
//             {
//                 title: "Add Event",
//                 icon: "bi-calendar2-plus-fill",
//                 path: "/",
//             },
//             {
//                 title: "Event History",
//                 icon: "bi-clock-history",
//                 path: "/",
//             },
//         ],
//     },
//     // Other menu items...
// ];

// export default sidebar;

// const sidebar = (tenantId) =>[
//     {
//         title: "Membership",
//         icon: "bi-person-badge-fill",
//         childrens: [
//             {
//                 title: "Members",
//                 icon: "bi-people-fill",
//                 path: `/\${tenantId}/members`, // Use backticks
//             },
//             {
//                 title: "Add Member",
//                 icon: "bi-person-plus-fill",
//                 path: `/\${tenantId}/memberAdd`, // Use backticks
//             },
//             {
//                 title: "Usage",
//                 icon: "bi-activity",
//                 path: "/about",
//             },
//         ],
//     },
//     {
//         title: "Events",
//         icon: "bi-calendar2-event-fill",
//         childrens: [
//             {
//                 title: "Calendar",
//                 icon: "bi-calendar3",
//                 path: "/",
//             },
//             {
//                 title: "Add Event",
//                 icon: "bi-calendar2-plus-fill",
//                 path: "/",
//             },
//             {
//                 title: "Event History",
//                 icon: "bi-clock-history",
//                 path: "/",
//             },
//         ],
//     },
//     // Other menu items...
// ];

// export default sidebar;

const sidebar = (tenantId) => [
    {
        title: "Membership",
        icon: "bi-person-badge-fill",
        childrens: [
            {
                title: "Members",
                icon: "bi-people-fill",
                path: `/${tenantId}/members`, // Links to MemberList
            },
            {
                title: "Add Member",
                icon: "bi-person-plus-fill",
                path: `/${tenantId}/memberAdd`, // Links to MemberAdd
            },
        ],
    },
    {
        title: "Events",
        icon: "bi-calendar2-event-fill",
        childrens: [
            {
                title: "Events",
                icon: "bi-calendar3-week",
                path: `/${tenantId}/events`, // Links to EventList
            },
            {
                title: "Add Event",
                icon: "bi-calendar2-plus-fill",
                path: `/${tenantId}/eventAdd`, // Links to EventAdd
            },
            {
                title: "Event History",
                icon: "bi-clock-history",
                path: `/${tenantId}/eventHistory`, // Links to Calendar
            },
            {
                title: "Calendar",
                icon: "bi-calendar3",
                path: `/${tenantId}/calendar`, // Links to Calendar
            },
        ],
    },
    {
        title: "Dashboard",
        icon: "bi-house-door-fill",
        childrens: [
            {
                title: "Home",
                icon: "bi-house-fill",
                path: `/${tenantId}/dashboard`, // Links to Dashboard
            },
        ],
    },
    {
        title: "Profile",
        icon: "bi-gear-fill",
        childrens: 
        [
            
            {
                title: "Add User",
                icon: "bi-person-plus-fill",
                path: `/${tenantId}/userAdd`, // Links to UserAdd
            },
            {
                title: "Integrations",
                icon: "bi-gear-fill",
                path: `/${tenantId}/integrations`, // Links to UserAdd
            },
            {
                title: "Profile",
                path: "/profile"
            },
            {
                title: "Account",
                path: "/settings/account"
            },
            {
                title: "Payment",
                path: "/settings/billing/payment"
            },
            {
                title: "Subscription",
                path: "/settings/billing/subscription"
            },
            {
                title: "Notifications",
                path: "/settings/notifications"
            },
            {
                title: "Reset Password",
                path: "/reset-password"
            },
            {
                title: "Logout",
                icon: "door-closed-fill",
                path: "/logout"
            }
        ]
    },
];

export default sidebar;



