
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DateTimePicker from 'react-datetime-picker';
import { FaCalendarAlt, FaClock } from 'react-icons/fa';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import axios from 'axios';
import ImageUploadModal from '../media/ImageUploadModal';
import CameraComponent from '../media/CameraComponent';

function EventItemAdd() {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [type, setType] = useState('');
  const [startdate, setStartDate] = useState(new Date()); // Default: Current date/time
  const [enddate, setEndDate] = useState(new Date(new Date().getTime() + 30 * 60000)); // Default: 30 min after start
  const [registerStatus, setRegisterStatus] = useState('');
  const [imagePath, setImagePath] = useState(null);
  const [showCamera, setShowCamera] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState(null);
  const navigate = useNavigate();
  const { tenant } = useParams();

  const baseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:5000';

  // const formatDateTime = (date) => {
  //   const d = new Date(date);
  //   const year = d.getFullYear();
  //   const month = String(d.getMonth() + 1).padStart(2, '0'); 
  //   const day = String(d.getDate()).padStart(2, '0');
  //   const hours = String(d.getHours()).padStart(2, '0');
  //   const minutes = String(d.getMinutes()).padStart(2, '0');
  //   return `${year}-${month}-${day} ${hours}:${minutes}`;
  // };

  const handleImageCaptured = (capturedImage) => {
    setImagePath(capturedImage);
    setShowCamera(false);
  };

  // Handle image selection from ImageUploadModal
  // const handleUploadComplete = (files) => {
  //   if (files.length > 0) {
  //     setSelectedFile(files[0]); // Store first selected file
  //     setImagePath(URL.createObjectURL(files[0])); // Show preview
  //   }
  //   setIsImageModalOpen(false);
  // };

  const handleUploadComplete = (files) => {
    setSelectedFiles(files);
    setIsImageModalOpen(false);
  };

  const formatDateTime = (date) => {
    if (!date) return "";
    return new Date(date).toISOString().slice(0, 19).replace("T", " ");
  };

  const handleEndDateChange = (date) => {
    if (!date) return; // Prevent errors if null
    if (date < startdate) {
      alert('End date cannot be before the start date!');
      return;
    }
    setEndDate(date);
  };

  const handleCancel = async (e) => {
    navigate(`/${tenant}/events`);
  }
 
const registerEvent = async (e) => {
  e.preventDefault();

  try {
    // Step 1: Register Event
    const response = await axios.post(`${baseUrl}/api/events/${tenant}/registerevent`, {
      name,
      description,
      type,
      startdate: formatDateTime(startdate),
      enddate: formatDateTime(enddate),
    });

    if (response.data.success) {
      const eventId = response.data.eventId;
      setRegisterStatus('Event created successfully!');
      
      // Step 2: Upload Images
      if (selectedFiles.length > 0) {
        await uploadEventImages(eventId);
      }

      navigate(`/${tenant}/${eventId}/eventDetail`);
    } else {
      setRegisterStatus('Failed to create event.');
    }
  } catch (error) {
    console.error('Error registering event:', error);
    setRegisterStatus('Error registering event. Please try again.');
  }

};

const uploadEventImages = async (eventId) => {
  if (!eventId || selectedFiles.length === 0) return;

  const formData = new FormData();
  formData.append("reftable", "t_event");
  formData.append("tableid", eventId);
  selectedFiles.forEach(file => formData.append('files', file));

  try {
    const uploadResponse = await axios.post(
      `${baseUrl}/api/${tenant}/${eventId}/uploadimage`,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );

    if (uploadResponse.data.success) {
      console.log('Images uploaded successfully:', uploadResponse.data);
    }
  } catch (error) {
    console.error('Error uploading images:', error);
  }
};


  return (
    <div className="event-form-container">
      <div className="card">
        <h4>Create Event</h4>
        <div className="card-body">
          <form onSubmit={registerEvent}>
            <div className="form-group">
              <label htmlFor="name">Event Name:</label>
              <input
                type="text"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter event name"
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="description">Event Description:</label>
              <input
                type="text"
                name="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Enter event description"
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="type">Event Type:</label>
              <input
                type="text"
                name="type"
                value={type}
                onChange={(e) => setType(e.target.value)}
                placeholder="Enter event type"
                required
              />
            </div>

            {/* <div className="date-time-container"> */}
              {/* <div className="form-group"> */}
                <label htmlFor="startdate">
                  <span><FaClock /></span> Start Date:
                </label>
                <DateTimePicker
                  onChange={(date) => date && setStartDate(new Date(date))}
                  value={startdate instanceof Date ? startdate : new Date()}
                  format="y-MM-dd h:mm a"
                />
              {/* </div> */}

              {/* <div className="form-group"> */}
                <label htmlFor="enddate">
                  <span><FaClock /></span> End Date:
                </label>
                <DateTimePicker
                  onChange={(date) => date && setEndDate(new Date(date))}
                  value={enddate instanceof Date ? enddate : new Date()}
                  format="y-MM-dd h:mm a"
                />
              {/* </div> */}
            {/* </div> */}
            {selectedFiles && selectedFiles.length > 0 && (
              <div className="preview-container" style={{ display: 'flex', flexWrap: 'wrap', marginTop: '10px' }}>
                {selectedFiles.map((file, index) => (
                  <img
                    key={index}
                    src={URL.createObjectURL(file)}
                    alt={`Preview ${index}`}
                    style={{ width: '100px', marginRight: '5px', marginBottom: '5px' }}
                  />
                ))}
              </div>
            )}
            <div>
              {imagePath ? (
                <div>
                  <img
                    src={imagePath}
                    alt="Captured"
                    style={{ width: '100px', marginBottom: '10px' }}
                  />
                  <button
                    type="button"
                    className="btn btn-secondary me-2"
                    onClick={() => setShowCamera(true)}
                  >
                    Retake Photo
                  </button>
                </div>
              ) : (
                <button
                  type="button"
                  className="btn btn-secondary me-2"
                  onClick={() => setShowCamera(true)}
                >
                  Take Photo
                </button>
              )}
              {imagePath ? (
              <div>
                {/* <img src={imagePath} alt="Preview" style={{ width: '100px' }} /> */}
                <button type="button" className="btn btn-secondary me-2" onClick={() => setIsImageModalOpen(true)}>Change Photo</button>
              </div>
            ) : (
              <button type="button" className="btn btn-secondary me-2" onClick={() => setIsImageModalOpen(true)}>Upload Photo</button>
            )}
            </div>
            <button onClick={handleCancel} className="btn btn-secondary me-2">
             Cancel
            </button>
            <button type="submit" className="btn btn-secondary me-2">
              Create Event
            </button>
          </form>
          {showCamera && (
            <CameraComponent
              onImageCaptured={handleImageCaptured}
              onClose={() => setShowCamera(false)}
            />
          )}
          {isImageModalOpen && (
            <ImageUploadModal
              tenantId={tenant}
              imageName={`${Date.now()}_${tenant}_${name}`}
              onUploadComplete={handleUploadComplete}
              onClose={() => setIsImageModalOpen(false)}
            />
          )}
          {registerStatus && <p className="status-message">{registerStatus}</p>}
        </div>
      </div>
    </div>
  );
}

export default EventItemAdd;
