import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import EventCard from "./EventCard";
import ImageUploadModal from '../media/ImageUploadModal';
import CameraComponent from '../media/CameraComponent';

const EventItemDetail = () => {
    const [type, setType] = useState("default");
    const [actionType, setActionType] = useState("default");
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [topSize, setTopSize] = useState([]);
    const [bottomSize, setBottomSize] = useState([]);
    const [shoeSize, setShoeSize] = useState([]);
    const [items, setItems] = useState([]);
    const [files, setFiles] = useState([]);
    const [registerStatus, setRegisterStatus] = useState('');
    const [imagePath, setImagePath] = useState(null);
    const [showCamera, setShowCamera] = useState(false);
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [statusMessage, setStatusMessage] = useState('');

    const clothingSizes = ["XS", "S", "M", "L", "XL", "XXL"];
    const [eventItem, setEventItem] = useState(null);
    const [eventItemImages, setEventItemImages] = useState([]);
    const [selectedImages, setSelectedImages] = useState([]);
    const [eventItemFiles, setEventItemFiles] = useState([]);
    const navigate = useNavigate();
    const { tenant, eventId, itemId } = useParams();

    const baseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:5000';

    const pantSizes = [
      { category: "M", range: "26 - 27", size: 26, label: "XXS" },
      { category: "M", range: "27 - 28", size: 27, label: "XS" },
      { category: "M", range: "28 - 29", size: 28, label: "XS" },
      { category: "M", range: "29 - 30", size: 29, label: "S" },
      { category: "M", range: "30 - 31", size: 30, label: "S" },
      { category: "M", range: "31 - 32", size: 31, label: "M" },
      { category: "M", range: "32 - 33", size: 32, label: "M" },
      { category: "M", range: "33 - 34", size: 33, label: "M/L" },
      { category: "M", range: "34 - 35", size: 34, label: "L" },
      { category: "M", range: "35 - 36", size: 35, label: "L" },
      { category: "M", range: "36 - 37", size: 36, label: "XL" },
      { category: "M", range: "38 - 39", size: 38, label: "XL" },
      { category: "M", range: "40 - 41", size: 40, label: "XXL" },
      { category: "M", range: "42 - 43", size: 42, label: "XXL" },
      { category: "M", range: "44 - 45", size: 44, label: "XXXL" },
      { category: "M", range: "46 - 47", size: 46, label: "XXXL" },
      { category: "M", range: "48 - 49", size: 48, label: "XXXL" },
      { category: "M", range: "50 - 51", size: 50, label: "4XL" },
      { category: "W", range: "23 - 25", size: "00", altSize: "23/24", label: "XXS" },
      { category: "W", range: "25 - 26", size: "0", altSize: "25", label: "XXS" },
      { category: "W", range: "26 - 27", size: "2", altSize: "26", label: "XS" },
      { category: "W", range: "27 - 28", size: "4", altSize: "27", label: "XS" },
      { category: "W", range: "28 - 29", size: "6", altSize: "28", label: "S" },
      { category: "W", range: "29 - 30", size: "8", altSize: "29", label: "S" },
      { category: "W", range: "30 - 31", size: "10", altSize: "30", label: "M" },
      { category: "W", range: "31 - 32", size: "12", altSize: "31", label: "M" },
      { category: "W", range: "32 - 33", size: "14", altSize: "32", label: "L" },
      { category: "W", range: "33 - 34", size: "16", altSize: "33", label: "L" },
      { category: "W", range: "34 - 35", size: "18", altSize: "34", label: "XL" },
      { category: "W", range: "36 - 37", size: "20", altSize: "36", label: "XL" },
      { category: "W", range: "38 - 39", size: "22", altSize: "38", label: "XXL" },
      { category: "W", range: "40 - 41", size: "24", altSize: "40", label: "XXL" }
  ];
  
    const shirtSizes = [
      "Extra Small",	
      "Small",
      "Medium",	
      "Large",	
      "X-Large",	
      "2X-Large",	
      "3X-Large",	
    ];
    const shoeSizes = ["6", "7", "8", "9", "10", "11", "12"];

useEffect(() => {
  const fetchItemDetails = async () => {
    try {
      const [itemRes, imagesRes] = await Promise.all([
        axios.get(`${baseUrl}/api/${tenant}/events/${eventId}/items/${itemId}`),
        axios.get(`${baseUrl}/api/${tenant}/events/${eventId}/items/${itemId}/images`)
      ]);

      const itemData = itemRes.data.item || {};
      setName(itemData.name || "");
      setDescription(itemData.description || "");
      setType(itemData.type || "default");

      // If attributes exist, extract them based on attributename.
      if (itemData.attributes) {
        const top = itemData.attributes
                      .filter(attr => attr.attributename === "top_size")
                      .map(attr => attr.attributevalue);
        const bottom = itemData.attributes
                      .filter(attr => attr.attributename === "bottom_size")
                      .map(attr => attr.attributevalue);
        const shoes = itemData.attributes
                      .filter(attr => attr.attributename === "shoe_size")
                      .map(attr => attr.attributevalue);
        setTopSize(top);
        setBottomSize(bottom);
        setShoeSize(shoes);
      }
      
      setEventItemImages(imagesRes.data.images || []);
      console.error('imagesRes.data.images', imagesRes.data.images);
    } catch (error) {
      console.error("Error fetching event item details:", error);
    }
  };

  fetchItemDetails();
}, [tenant, eventId, itemId]);

const handleNameChange = (e) => setName(e.target.value);
const handleDescriptionChange = (e) => setDescription(e.target.value);
const handleTypeChange = (selectedType) => setType(selectedType)

    const handleCategoryChange = (selectedType) => {
        setType(selectedType);
    };
      
      const handleImageCaptured = (capturedImage) => {
      setImagePath(capturedImage);
      setShowCamera(false);
      };
  
      const handleFileChange = (e) => {
          setFiles([...e.target.files]);
      };
  
      const handleItem = () => {
        if (!eventId) {
            console.error("❌ eventId is missing! Cannot open ItemAdd.");
            return;
        }
        navigate(`/${tenant}/${eventId}/eventItemAdd`);
      };

    const handleImageSelect = (imgPath) => {
      setSelectedImages((prev) =>
        prev.includes(imgPath)
          ? prev.filter(i => i !== imgPath)
          : [...prev, imgPath]
      );
    };

    const handleUploadComplete = async (files) => {
        try {
            if (files.length === 0) return;
    
            setFiles(files);
            setImagePath(URL.createObjectURL(files[0])); // Show preview of first image
            setIsImageModalOpen(false);
    
            console.log("Files uploaded successfully:", files);
    
            const formData = new FormData();
            formData.append("reftable", "t_event_item"); // Specify correct reference table
            formData.append("tableid", eventId); // Ensure tableid is passed
            files.forEach((file) => formData.append("files", file));
    
          try {
            const response = await axios.post(
                `${baseUrl}/api/${tenant}/${eventId}/uploaditemimage`,
                formData,
                { headers: { "Content-Type": "multipart/form-data" } }
            );
        
            if (response.data.success) {
                console.log("✅ Images uploaded successfully");
            }
        } catch (error) {
            console.error("❌ Error uploading images:", error);
        }
    
            setRegisterStatus("Files uploaded successfully!");
        } catch (error) {
            console.error("Error linking uploaded files:", error);
        }
      };

    const updateItem = async (e) => {
      e.preventDefault();
      if (!name || !description || !type) {
        alert("Please fill out all required fields: Name, Description, and Type.");
        return;
      }
      // Build payload for update (and include attributes if needed)
      const payload = {
        name,
        description,
        type,
        topSize,
        bottomSize,
        shoeSize
      };
      try {
        const response = await axios.put(`${baseUrl}/api/events/${tenant}/events/${eventId}/items/${itemId}`, payload);
        if (response.data.success) {
          setRegisterStatus("Item updated successfully!");
        } else {
          setRegisterStatus("Failed to update item.");
        }
      } catch (error) {
        console.error("Error updating event item:", error);
        setRegisterStatus("Error updating item.");
      }
    };

    const handleInputChange = (e) => {
      setEventItem((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };


    const handleSubmitAction = async () => {
      try {
          // const eventActionUrl = `http://localhost:3000/${tenant}/${eventId}/${itemId}/eventaction`;
          const eventActionUrl = `${baseUrl}/${tenant}/${eventId}/${itemId}/eventaction`;

          const startdate = new Date().toISOString().split("T")[0]; // Today's date
          const starttime = new Date().toTimeString().split(" ")[0]; // Current time
  
          const actionData = {
              tenant,
              eventId,
              itemId,
              actionType,
              url: eventActionUrl,
              startdate,
              starttime
          };
  
          console.log("Submitting Event Action:", actionData);
  
          const response = await axios.post(`${baseUrl}/api/${tenant}/events/${eventId}/items/${itemId}/action`, actionData);
  
          if (response.data.success) {
            alert("Event action submitted successfully! URL: " + eventActionUrl);
        } else {
            alert("Failed to submit event action: " + response.data.message);
        }
      } catch (error) {
          console.error("Error submitting event action:", error);
          alert("Failed to submit event action.");
      }
  };
  

    return (
        <div className="main-content">
          <div className="col-md-1">
          <div className="card">
            <h4>Event Item Detail</h4>
            {eventItemImages.length > 0 ? (
              <div className="image-grid">
                  {eventItemImages.map((image, index) => (
                      <div
                          key={index}
                          className={`image-card ${selectedImages.includes(image.path) ? 'selected' : ''}`}
                          onClick={() => handleImageSelect(image.path)}
                      >
                          <img src={image.path} 
                              alt={`Event Item Image ${index + 1}`} 
                              className="event-thumbnail"
                              onError={(e) => e.target.src = "/event.png"} // Default image on error
                          />
                      </div>
                  ))}
              </div>
            ) : (
                <p>No images available for this event item.</p>
            )}

            <div className="card-body">
              <form onSubmit={updateItem}>
              <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input
                        type="text"
                        className="form-control"
                        id="name"
                        value={name}
                        onChange={handleNameChange}
                        placeholder="Item Name"
                        required
                    />
                </div>
              <div className="form-group">
                    <label htmlFor="description">Description</label>
                    <textarea
                        className="form-control"
                        id="description"
                        value={description}
                        onChange={handleDescriptionChange}
                        placeholder="Item Description"
                        required
                    />
                </div>
    
            <div className="form-group">
            <label>Select Item Type:</label>
        <table>
        <tbody>
            <tr>
                <td>
                    <input 
                        type="checkbox"
                        value="default"
                        checked={type === "default"}
                        // onChange={() => handleCategoryChange("default")}
                        onChange={() => handleTypeChange("default")}
                    /> Default
                </td>
                <td>
                    <input 
                        type="checkbox"
                        value="clothing"
                        checked={type === "clothing"}
                        // onChange={() => handleCategoryChange("clothing")}
                        onChange={() => handleTypeChange("clothing")}
                    /> Clothing
                </td>
                <td>
                    <input 
                        type="checkbox"
                        value="shoes"
                        checked={type === "shoes"}
                        // onChange={() => handleCategoryChange("shoes")}
                        onChange={() => handleTypeChange("shoes")}
                    /> Shoes
                </td>
            </tr>
          </tbody>
        </table>
    </div>
    
            {/* Clothing Size Dropdowns */}
            {type === "clothing" && (
              <>
                  <div className="form-group">
                      <label>Shirt Size:</label>
                      <select
                          multiple
                          className="form-control"
                          value={topSize}
                          onChange={(e) =>
                              setTopSize([...e.target.selectedOptions].map(o => o.value))
                          }
                      >
                          {shirtSizes.map(size => (
                              <option key={size} value={size}>{size}</option>
                          ))}
                      </select>
                  </div>
    
                  <div className="form-group">
                      <label>Pant Size:</label>
                      <select
                          multiple
                          className="form-control"
                          value={bottomSize}
                          onChange={(e) =>
                              setBottomSize([...e.target.selectedOptions].map(o => o.value))
                          }
                      >
                          {pantSizes.map((sizeObj, index) => (
                            <option key={index} value={sizeObj.size}>
                            {sizeObj.category} {sizeObj.label} ({sizeObj.range})
                            </option>
                        ))}
                      </select>
                  </div>
              </>
          )}
    
            {/* Shoe Size Dropdown */}
            {type === "shoes" && (
                <div className="form-group">
                    <label>Shoe Size:</label>
                    <select
                        multiple
                        className="form-control"
                        value={shoeSize}
                        onChange={(e) =>
                            setShoeSize([...e.target.selectedOptions].map(o => o.value))
                        }
                    >
                        {shoeSizes.map(size => (
                            <option key={size} value={size}>{size}</option>
                        ))}
                    </select>
                </div>
            )}
            
            <div>
                  {imagePath ? (
                    <div>
                      <img
                        src={imagePath}
                        alt="Captured"
                        style={{ width: '100px', marginBottom: '10px' }}
                      />
                      <button
                        type="button"
                        className="btn btn-secondary me-2"
                        onClick={() => setShowCamera(true)}
                      >
                        Retake Photo
                      </button>
                    </div>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-secondary me-2"
                      onClick={() => setShowCamera(true)}
                    >
                      Take Photo
                    </button>
                  )}
                  {imagePath ? (
                  <div>
                    {/* <img src={imagePath} alt="Preview" style={{ width: '100px' }} /> */}
                    <button type="button" className="btn btn-secondary me-2" onClick={() => setIsImageModalOpen(true)}>Change Photo</button>
                  </div>
                ) : (
                  <button type="button" className="btn btn-secondary me-2" onClick={() => setIsImageModalOpen(true)}>Upload Photo</button>
                )}
                </div>
    
                {/* Event Items List Table */}
                {items.length > 0 && (
                  <table className="w-full border-collapse border border-gray-300 mt-4">
                    <tbody>
                      {items && items.length > 0 ? (
                        items.map((item, index) => (
                          item ? ( // ✅ Ensure item is not undefined
                            <tr key={item.id || index} className="border">
                              <td className="border px-4 py-2">{item.name || "Unnamed Item"}</td>
                              <td className="border px-4 py-2">{item.type || "Unknown Type"}</td>
                              {/* <td className="border px-4 py-2 text-center">
                                <button className="text-red-500" onClick={() => handleDelete(item.id)}>❌</button>
                              </td> */}
                            </tr>
                          ) : null
                        ))
                      ) : <p>No items available.</p>}
    
                    </tbody>
                  </table>
                )}
    
                <button onClick={() => navigate(-1)} className="btn btn-secondary">
                  Back
                </button>
                <button onClick={handleItem} type="submit" className="btn btn-primary mt-3">
                  Update Item
                </button>

                {/* Radio Buttons for Action Type Selection */}
                <div className="action-type-selection">
                    <label>Action Type:</label>
                    <div className="radio-group">
                        <label>
                            <input
                                type="radio"
                                value="default"
                                checked={actionType === "default"}
                                onChange={() => setActionType("default")}
                            />
                            Default
                        </label>
                        <label>
                            <input
                                type="radio"
                                value="auction"
                                checked={actionType === "auction"}
                                onChange={() => setActionType("auction")}
                            />
                            Auction
                        </label>
                        <label>
                            <input
                                type="radio"
                                value="raffle"
                                checked={actionType === "raffle"}
                                onChange={() => setActionType("raffle")}
                            />
                            Raffle
                        </label>
                    </div>
                </div>

                <button onClick={handleSubmitAction} className="btn btn-success mt-3">
                    Submit Action
                </button>

                {showCamera && (
                <CameraComponent
                  onImageCaptured={handleImageCaptured}
                  onClose={() => setShowCamera(false)}
                />
              )}
              {isImageModalOpen && (
                <ImageUploadModal
                  tenantId={tenant}
                  imageName={`${Date.now()}_${tenant}_${name}`}
                  onUploadComplete={handleUploadComplete}
                  onClose={() => setIsImageModalOpen(false)}
                />
              )}
              {registerStatus && <p className="mt-3">{registerStatus}</p>}
              </form>
            </div>
          </div>
        </div>
        </div>
      );
};

export default EventItemDetail;
