// import React, { useState } from "react";
// import axios from "axios";

// const SendEmail = () => {
//   const [emailData, setEmailData] = useState({
//     to: "",
//     subject: "",
//     message: "",
//   });
//   const [status, setStatus] = useState("");

//   const handleChange = (e) => {
//     setEmailData({ ...emailData, [e.target.name]: e.target.value });
//   };

//   const sendEmail = async (e) => {
//     e.preventDefault();
//     setStatus("Sending...");

//     try {
//       const response = await axios.post("http://localhost:5000/api/email/send", emailData);
//       setStatus(response.data.message);
//     } catch (error) {
//       setStatus("Failed to send email.");
//       console.error("Error:", error);
//     }
//   };

//   return (
//     <div className="email-container">
//       <h2>Send an Email</h2>
//       <form onSubmit={sendEmail}>
//         <input
//           type="email"
//           name="to"
//           placeholder="Recipient Email"
//           value={emailData.to}
//           onChange={handleChange}
//           required
//         />
//         <input
//           type="text"
//           name="subject"
//           placeholder="Subject"
//           value={emailData.subject}
//           onChange={handleChange}
//           required
//         />
//         <textarea
//           name="message"
//           placeholder="Your Message"
//           value={emailData.message}
//           onChange={handleChange}
//           required
//         />
//         <button type="submit">Send Email</button>
//       </form>
//       {status && <p>{status}</p>}
//     </div>
//   );
// };

// export default SendEmail;

import React, { useState } from "react";
import axios from "axios";

const SendEmail = () => {
  const [emailData, setEmailData] = useState({
    to: "",
    subject: "",
    message: "",
    schedule: "",
  });
  const [attachment, setAttachment] = useState(null);
  const [status, setStatus] = useState("");

  const handleChange = (e) => {
    setEmailData({ ...emailData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setAttachment(e.target.files[0]);
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    setStatus("Sending...");

    const baseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:5000';
    const formData = new FormData();
    formData.append("to", emailData.to);
    formData.append("subject", emailData.subject);
    formData.append("message", emailData.message);
    formData.append("schedule", emailData.schedule);
    if (attachment) {
      formData.append("attachment", attachment);
    }

    try {
      const response = await axios.post(`${baseUrl}/api/email/send`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      setStatus(response.data.message);
    } catch (error) {
      setStatus("Failed to send email.");
      console.error("Error:", error);
    }
  };

  return (
    <div className="email-container">
      <h2>Send an Email</h2>
      <form onSubmit={sendEmail}>
        <input
          type="email"
          name="to"
          placeholder="Recipient Email"
          value={emailData.to}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="subject"
          placeholder="Subject"
          value={emailData.subject}
          onChange={handleChange}
          required
        />
        <textarea
          name="message"
          placeholder="Your Message"
          value={emailData.message}
          onChange={handleChange}
          required
        />
        <input type="file" onChange={handleFileChange} />
        <input
          type="datetime-local"
          name="schedule"
          value={emailData.schedule}
          onChange={handleChange}
        />
        <button type="submit">Send Email</button>
      </form>
      {status && <p>{status}</p>}
    </div>
  );
};

export default SendEmail;
