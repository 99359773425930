
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

function OwnerLogin() {
  const [email, setEmail] = useState(''); 
  const [ownerUsername, setOwnerUsername] = useState(''); // Renamed from username
  const [password, setPassword] = useState('');
  const [loginStatus, setLoginStatus] = useState('');
  const navigate = useNavigate();

  const baseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:5000';

const loginowner = async (e) => {
  e.preventDefault();

  try {
      const response = await axios.post(`${baseUrl}/api/tenants/loginowner`, {
          email,
          username: ownerUsername,
          password,
      });

      // Check if login is successful
      if (response?.data?.success) {
          console.log('Login successful:', response.data);
          const owner = response.data.owner;

          // Save owner ID and data to localStorage for persistence
          localStorage.setItem('ownerId', owner.id);
          localStorage.setItem('ownerData', JSON.stringify(owner)); // Save the entire owner object

          // Redirect to TenantAdd and pass owner data
          navigate('/tenantadd', { state: { ...owner } });
      } else {
          console.error('Login failed:', response?.data?.message || 'Unknown error');
          setLoginStatus(response?.data?.message || 'Login failed. Please try again.');
      }
  } catch (error) {
      console.error('Login failed:', error.response?.data || error.message);
      setLoginStatus('Login failed. Please try again.');
  }
};

  return (
    <div className="owner-add-container">
        <div className="card">
        <h4 className="card-title">Login Here</h4>
            <div className="card-body">
                <form onSubmit={loginowner} className="form">
                    <div className="form-group">
                      <label htmlFor="email">Email:</label>
                        <input
                          className="textInput"
                          type="text"
                          name="email"
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder=" Enter your Email"
                          required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="username">Username:</label>
                        <input
                          className="textInput"
                          type="text"
                          name="username"
                          onChange={(e) => setOwnerUsername(e.target.value)} // Updated here
                          placeholder=" Enter your Username"
                          required
                        />
                    </div>
                    <div className="form-group">
                      <label htmlFor="password">Password:</label>
                        <input
                          className="textInput"
                          type="password"
                          name="password"
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder=" Enter your Password"
                          required
                        />
                    </div>
                    <button type="submit" className="btn btn-primary">Login</button>
                    {loginStatus && <p>{loginStatus}</p>}
                </form>
            </div>
        </div>
    </div>
  );
}

export default OwnerLogin;

