
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  // useParams,
} from "react-router-dom";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import firebase from "firebase/compat/app";
import "firebase/compat/messaging";
import Home from "./Home";
import Login from "./Login";
import OwnerAdd from "./tenant/OwnerAdd";
import OwnerLogin from "./tenant/OwnerLogin";
import TenantAdd from "./tenant/TenantAdd";
import TenantRoutes from "./TenantRoutes";
import CameraComponent from "./media/CameraComponent";
import ImageUploadModal from "./media/ImageUploadModal";
import SendEmail from "./SendEmail";

const firebaseConfig = {
    apiKey: "YOUR_API_KEY",
    authDomain: "YOUR_AUTH_DOMAIN",
    projectId: "YOUR_PROJECT_ID",
    storageBucket: "YOUR_STORAGE_BUCKET",
    messagingSenderId: "YOUR_MESSAGING_SENDER_ID",
    appId: "YOUR_APP_ID",
  };
  
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }
  const messaging = getMessaging();

  function requestNotificationPermission() {
    Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
            getToken(messaging, { vapidKey: "YOUR_PUBLIC_VAPID_KEY" })
                .then((currentToken) => {
                    if (currentToken) {
                        console.log("Notification Token:", currentToken);
                    } else {
                        console.log("No registration token available.");
                    }
                })
                .catch((err) => {
                    console.log("Error getting token: ", err);
                });

            onMessage(messaging, (payload) => {
                console.log("Message received: ", payload);
                toast.info(`📢 ${payload.notification.title}: ${payload.notification.body}`);
            });
        }
    });
  }

  function App() {

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen((prev) => !prev);
    };

    return (
        // <Router basename={process.env.REACT_APP_BASE_URL}>
        <Router>
            <div className="main">
                {/* <div className="container"> */}
                {/* <AppHeader isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
                <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} /> */}
                <div className="main-content">
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/owneradd" element={<OwnerAdd />} />
                        <Route path="/tenantadd" element={<TenantAdd />} />
                        <Route path="/ownerlogin" element={<OwnerLogin />} />
                        <Route path="/:tenant/*" element={<TenantRoutes />} />
                        <Route path="/camera" element={<CameraComponent />} />
                        <Route path="/imageUpload" element={<ImageUploadModal />} />
                        <Route path="/sendemail" element={<SendEmail />} />
                    </Routes>
                </div>
            </div>
            <ToastContainer />
        </Router>
    );
}

export default App;


