
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import ImageUploadModal from '../media/ImageUploadModal';
import CameraComponent from '../media/CameraComponent';

const EventItemAdd = () => {
    const [type, setType] = useState("default")
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [topSize, setTopSize] = useState([]);
    const [bottomSize, setBottomSize] = useState([]);
    const [shoeSize, setShoeSize] = useState([]);
    const [items, setItems] = useState([]);
    const [files, setFiles] = useState([]);
    // const [image, setImage] = useState(null);
    const [registerStatus, setRegisterStatus] = useState('');
    const [imagePath, setImagePath] = useState(null);
    const [showCamera, setShowCamera] = useState(false);
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [statusMessage, setStatusMessage] = useState('');
    const navigate = useNavigate();
    const { tenant, eventId } = useParams();

    const baseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:5000';

    const clothingSizes = ["XS", "S", "M", "L", "XL", "XXL"];
    const pantSizes = [
      { category: "M", range: "26 - 27", size: 26, label: "XXS" },
      { category: "M", range: "27 - 28", size: 27, label: "XS" },
      { category: "M", range: "28 - 29", size: 28, label: "XS" },
      { category: "M", range: "29 - 30", size: 29, label: "S" },
      { category: "M", range: "30 - 31", size: 30, label: "S" },
      { category: "M", range: "31 - 32", size: 31, label: "M" },
      { category: "M", range: "32 - 33", size: 32, label: "M" },
      { category: "M", range: "33 - 34", size: 33, label: "M/L" },
      { category: "M", range: "34 - 35", size: 34, label: "L" },
      { category: "M", range: "35 - 36", size: 35, label: "L" },
      { category: "M", range: "36 - 37", size: 36, label: "XL" },
      { category: "M", range: "38 - 39", size: 38, label: "XL" },
      { category: "M", range: "40 - 41", size: 40, label: "XXL" },
      { category: "M", range: "42 - 43", size: 42, label: "XXL" },
      { category: "M", range: "44 - 45", size: 44, label: "XXXL" },
      { category: "M", range: "46 - 47", size: 46, label: "XXXL" },
      { category: "M", range: "48 - 49", size: 48, label: "XXXL" },
      { category: "M", range: "50 - 51", size: 50, label: "4XL" },
      { category: "W", range: "23 - 25", size: "00", altSize: "23/24", label: "XXS" },
      { category: "W", range: "25 - 26", size: "0", altSize: "25", label: "XXS" },
      { category: "W", range: "26 - 27", size: "2", altSize: "26", label: "XS" },
      { category: "W", range: "27 - 28", size: "4", altSize: "27", label: "XS" },
      { category: "W", range: "28 - 29", size: "6", altSize: "28", label: "S" },
      { category: "W", range: "29 - 30", size: "8", altSize: "29", label: "S" },
      { category: "W", range: "30 - 31", size: "10", altSize: "30", label: "M" },
      { category: "W", range: "31 - 32", size: "12", altSize: "31", label: "M" },
      { category: "W", range: "32 - 33", size: "14", altSize: "32", label: "L" },
      { category: "W", range: "33 - 34", size: "16", altSize: "33", label: "L" },
      { category: "W", range: "34 - 35", size: "18", altSize: "34", label: "XL" },
      { category: "W", range: "36 - 37", size: "20", altSize: "36", label: "XL" },
      { category: "W", range: "38 - 39", size: "22", altSize: "38", label: "XXL" },
      { category: "W", range: "40 - 41", size: "24", altSize: "40", label: "XXL" }
  ];
  
    const shirtSizes = [
      "Extra Small",	
      "Small",
      "Medium",	
      "Large",	
      "X-Large",	
      "2X-Large",	
      "3X-Large",	
    ];
    const shoeSizes = ["6", "7", "8", "9", "10", "11", "12"];

    const handleCategoryChange = (selectedType) => {
      setType(selectedType);
  };
    
    const handleImageCaptured = (capturedImage) => {
    setImagePath(capturedImage);
    setShowCamera(false);
    };

    const handleFileChange = (e) => {
        setFiles([...e.target.files]);
    };

    const handleItem = () => {
      if (!eventId) {
          console.error("❌ eventId is missing! Cannot open ItemAdd.");
          return;
      }
      navigate(`/${tenant}/${eventId}/eventItemAdd`);
  };

const handleUploadComplete = async (files) => {
    try {
        if (files.length === 0) return;

        setFiles(files);
        setImagePath(URL.createObjectURL(files[0])); // Show preview of first image

        const formData = new FormData();
        files.forEach((file) => formData.append("files", file));
    } catch (error) {
        console.error("❌ Error uploading images:", error);
    }
    setIsImageModalOpen(false);
};

  const registerItem = async (e) => {
    e.preventDefault();

    if (!name || !description || !type) {
        console.error("❌ Missing required fields!");
        return;
    }

    try {
      console.log("📌 Sending Event Item Data:", { tenant, eventId, name, description, type });
        // Step 1: Create the Event Item first
        const formData = new FormData();
        formData.append("name", name);
        formData.append("description", description);
        formData.append("type", type);

        if (type === "clothing") {
          topSize.forEach(size => formData.append("topSize", size));
          bottomSize.forEach(size => formData.append("bottomSize", size));
        };

        if (type === "shoes") {
          shoeSize.forEach(size => formData.append("shoeSize", size));
        }

        console.log("📌 Sending FormData:", Object.fromEntries(formData));

        const response = await axios.post(
            `${baseUrl}/api/${tenant}/events/${eventId}/items`,
            formData
        );

        if (response.data.success) {
            console.log("✅ Event item added successfully!", response.data);
            const itemId = response.data.itemId;

            // Step 2: Upload Images After Event Item Creation
            if (files.length > 0) {
                const imageFormData = new FormData();
                imageFormData.append("reftable", "t_event_item");
                imageFormData.append("tableid", itemId); // Use the created item's ID
                imageFormData.append("parenttable", "t_event");
                imageFormData.append("parenttableid", eventId);
                files.forEach(file => imageFormData.append("files", file));

                const uploadResponse = await axios.post(
                    `${baseUrl}/api/${tenant}/${eventId}/items/${itemId}/uploadimage`,
                    imageFormData,
                    { headers: { "Content-Type": "multipart/form-data" } }
                );

                if (uploadResponse.data.success) {
                    console.log("✅ Images uploaded successfully!");
                } else {
                    console.error("❌ Failed to upload images:", uploadResponse.data.message);
                }
            }

            navigate(`/${tenant}/${eventId}/${itemId}/eventItemDetail`);
        } else {
            console.error("❌ Failed to add event item:", response.data.message);
        }
    } catch (error) {
        console.error("❌ Error adding event item:", error);
    }
};

  

    return (
    <div className="main-content">
      <div className="col-md-1">
      <div className="card">
        <h4>Add Event Item</h4>
        <div className="card-body">
          <form onSubmit={registerItem}>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              className="form-control"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Name"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="description">Description:</label>
            <textarea
              className="form-control"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            ></textarea>
          </div>
          

        <div className="form-group">
        <label>Select Item Type:</label>
        <table>
        <tbody>
            <tr>
                <td>
                    <input 
                        type="checkbox"
                        checked={type === "default"}
                        onChange={() => handleCategoryChange("default")}
                    /> Default
                </td>
                <td>
                    <input 
                        type="checkbox"
                        checked={type === "clothing"}
                        onChange={() => handleCategoryChange("clothing")}
                    /> Clothing
                </td>
                <td>
                    <input 
                        type="checkbox"
                        checked={type === "shoes"}
                        onChange={() => handleCategoryChange("shoes")}
                    /> Shoes
                </td>
            </tr>
          </tbody>
        </table>
    </div>

        {type === "clothing" && (
          <>
              <div className="form-group">
                  <label>Shirt Size:</label>
                  <select
                      multiple
                      className="form-control"
                      value={topSize}
                      onChange={(e) =>
                          setTopSize([...e.target.selectedOptions].map(o => o.value))
                      }
                  >
                      {shirtSizes.map(size => (
                          <option key={size} value={size}>{size}</option>
                      ))}
                      {/* {shirtSizes.map((sizeObj, index) => (
                            <option key={index} value={sizeObj.size}>
                                {sizeObj.label} ({sizeObj.range})
                            </option>
                        ))} */}
                  </select>
              </div>
              {/* <div className="form-group">
                <label htmlFor="size">Size:</label>
                <select className="form-control" value={topSize} onChange={(e) => setTopSize([...e.target.selectedOptions].map(o => o.value))}>
                  {shirtSizes.map(size => (
                            <option key={size} value={size}>{size}</option>
                        ))}
                </select>
              </div> */}

              <div className="form-group">
                  <label>Pant Size:</label>
                  <select
                      multiple
                      className="form-control"
                      value={bottomSize}
                      onChange={(e) =>
                          setBottomSize([...e.target.selectedOptions].map(o => o.value))
                      }
                  >
                      {pantSizes.map((sizeObj, index) => (
                            <option key={index} value={sizeObj.size}>
                            {sizeObj.category} {sizeObj.label} ({sizeObj.range})
                            </option>
                        ))}
                  </select>
              </div>
          </>
      )}

        {/* Shoe Size Dropdown */}
        {type === "shoes" && (
            <div className="form-group">
                <label>Shoe Size:</label>
                <select
                    multiple
                    className="form-control"
                    value={shoeSize}
                    onChange={(e) =>
                        setShoeSize([...e.target.selectedOptions].map(o => o.value))
                    }
                >
                    {shoeSizes.map(size => (
                        <option key={size} value={size}>{size}</option>
                    ))}
                </select>
            </div>
        )}

        {selectedFiles && selectedFiles.length > 0 && (
          <div className="preview-container" style={{ display: 'flex', flexWrap: 'wrap', marginTop: '10px' }}>
            {selectedFiles.map((file, index) => (
              <img
                key={index}
                src={URL.createObjectURL(file)}
                alt={`Item Preview ${index}`}
                style={{ width: '100px', marginRight: '5px', marginBottom: '5px' }}
              />
            ))}
          </div>
        )}
        
        <div>
              {imagePath ? (
                <div>
                  <img
                    src={imagePath}
                    alt="Captured"
                    style={{ width: '100px', marginBottom: '10px' }}
                  />
                  <button
                    type="button"
                    className="btn btn-secondary me-2"
                    onClick={() => setShowCamera(true)}
                  >
                    Retake Photo
                  </button>
                </div>
              ) : (
                <button
                  type="button"
                  className="btn btn-secondary me-2"
                  onClick={() => setShowCamera(true)}
                >
                  Take Photo
                </button>
              )}
              {imagePath ? (
              <div>
                {/* <img src={imagePath} alt="Preview" style={{ width: '100px' }} /> */}
                <button type="button" className="btn btn-secondary me-2" onClick={() => setIsImageModalOpen(true)}>Change Photo</button>
              </div>
            ) : (
              <button type="button" className="btn btn-secondary me-2" onClick={() => setIsImageModalOpen(true)}>Upload Photo</button>
            )}
            </div>

            {/* Event Items List Table */}
            {items.length > 0 && (
              <table className="w-full border-collapse border border-gray-300 mt-4">
                {/* <thead>
                  <tr className="bg-gray-200">
                    <th className="border px-4 py-2">Item</th>
                    <th className="border px-4 py-2">Type</th>
                    <th className="border px-4 py-2">Action</th>
                  </tr>
                </thead> */}
                <tbody>
                  {/* {items.map((item) => (
                    <tr key={item.id} className="border">
                      <td className="border px-4 py-2">{item.name}</td>
                      <td className="border px-4 py-2">{item.type}</td>
                      <td className="border px-4 py-2 text-center">
                      </td>
                    </tr>
                  ))} */}
                  {items && items.length > 0 ? (
                    items.map((item, index) => (
                      item ? ( // ✅ Ensure item is not undefined
                        <tr key={item.id || index} className="border">
                          <td className="border px-4 py-2">{item.name || "Unnamed Item"}</td>
                          <td className="border px-4 py-2">{item.type || "Unknown Type"}</td>
                          {/* <td className="border px-4 py-2 text-center">
                            <button className="text-red-500" onClick={() => handleDelete(item.id)}>❌</button>
                          </td> */}
                        </tr>
                      ) : null
                    ))
                  ) : <p>No items available.</p>}

                </tbody>
              </table>
            )}

            <button onClick={() => navigate(-1)} className="btn btn-secondary">
              Back
            </button>
            <button onClick={handleItem} type="submit" className="btn btn-primary mt-3">
              Add Item
            </button>
            {showCamera && (
            <CameraComponent
              onImageCaptured={handleImageCaptured}
              onClose={() => setShowCamera(false)}
            />
          )}
          {isImageModalOpen && (
            <ImageUploadModal
              tenantId={tenant}
              imageName={`${Date.now()}_${tenant}_${name}`}
              onUploadComplete={handleUploadComplete}
              onClose={() => setIsImageModalOpen(false)}
            />
          )}
          {registerStatus && <p className="mt-3">{registerStatus}</p>}
          </form>
        </div>
      </div>
    </div>
    </div>
  );
};

export default EventItemAdd;
