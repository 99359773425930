
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function Login() {
  const [credentials, setCredentials] = useState({ username: '', password: '' });
  const navigate = useNavigate();

  const baseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:5000';

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  const login = async (e) => {
    e.preventDefault();
    try {
        const response = await axios.post(`${baseUrl}/api/login`, credentials);
        if (response.status === 200 && response.data.message === 'Login successful') {
            const { tenantId, token } = response.data;
            // localStorage.setItem('authToken', token); // Store token for future authentication
            try {
              localStorage.setItem('authToken', token);
            } catch (error) {
                console.error("Storage access error:", error);
            }
          
            console.log('Login successful, tenantId:', tenantId);
            toast.success('Login successful!');
            navigate(`/${tenantId}/dashboard`);
        } else {
            console.error('Unexpected response:', response.data);
            toast.error(response.data.message || 'Unexpected error.');
        }
    } catch (error) {
        if (error.response?.status === 401) {
            toast.error('Invalid username or password.');
        } else {
            console.error('Login error:', error.response?.data || error.message);
            toast.error('Failed to login. Please try again.');
        }
    }
};

const handleOwner = () => {
  navigate(`/ownerAdd`);
};


  return (
    <div className="col=md-4">
        <div className="card">
            <div className="card-body">
            <h4>Login Here</h4>
                <form onSubmit={login}>
                    <div className="form-group">
                      <label htmlFor="username">Username</label>
                      <input
                        className="textInput"
                        type="text"
                        name="username"
                        value={credentials.username}
                        onChange={handleInputChange}
                        placeholder="Enter your Username"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="password">Password</label>
                      <input
                        className="textInput"
                        type="password"
                        name="password"
                        value={credentials.password}
                        onChange={handleInputChange}
                        placeholder="Enter your Password"
                        required
                      />
                    </div>
                    <button className="btn btn-primary" type="submit">
                      Login
                    </button>
                    <button onClick={handleOwner} className="btn btn-primary" type="submit">
                      Create Account
                    </button>
                </form>
            </div>
        </div>
    </div>
  );
}

export default Login;







