
// import React, { useRef } from "react";
// import { useLocation, useNavigate, Link } from "react-router-dom";
// import "./AppHeader.css";

// const AppHeader = ({ isSidebarOpen, toggleSidebar  }) => {
//     const navigate = useNavigate();
//     const location = useLocation();
//     const searchRef = useRef();

//     // Check if the current path contains "List"
//     const showSearchInput = location.pathname.includes("List");

//     const submitHandler = (evt) => {
//         evt.preventDefault();
//         navigate(`/members?searchText=${searchRef.current.value}`);
//     };

//     return (
//         <div className={`app-header ${isSidebarOpen ? "with-sidebar" : ""}`}  style={{ maxWidth: "100vw", position: "fixed", top: 0, left: 0, width: "100%", zIndex: 1000 }}>
//             <div className="header-container">
//             <table>
//                 <thead>
//                     <tr>
//                         <th>
//                         </th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     <tr>
//                         <td>
//                             <h2 className="brand-title">
//                                 <Link to="/" style={{ textDecoration: "none" }}>
//                                     On-Screen
//                                 </Link>
//                             </h2>
//                         </td>
//                         <td>
//                             <div className="logo-container" onClick={toggleSidebar} style={{ cursor: "pointer" }}>
//                                 <img src="/images/logo-xsm.png" alt="Logo" className="logo" />
//                             </div>
//                         </td>
//                     </tr>
//                     <tr>
//                         <td>
//                             <form className="search-form" onSubmit={submitHandler}>
//                                 <input
//                                     type="search"
//                                     ref={searchRef}
//                                     placeholder="Search..."
//                                     className="search-input"
//                                 />
//                             </form>
//                         </td>
//                     </tr>
//                 </tbody>
//             </table>
//             </div>
//         </div>
//     );
// };

// export default AppHeader;

// AppHeader.jsx
import React, { useRef } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import "./AppHeader.css";

const AppHeader = ({ isSidebarVisible, toggleSidebar }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchRef = useRef();

  // Check if the current pathname indicates the MemberList or EventList route.
  const { pathname } = location;
  const showSearchForMembers = pathname.includes("members");
  const showSearchForEvents = pathname.includes("events");

  // Only show the search form on either the MemberList or EventList pages.
  const showSearch = showSearchForMembers || showSearchForEvents;

  // Submit handler that sends the search query to the appropriate route.
  const submitHandler = (evt) => {
    evt.preventDefault();
    if (showSearchForMembers) {
      navigate(`/members?searchText=${searchRef.current.value}`);
    } else if (showSearchForEvents) {
      navigate(`/events?searchText=${searchRef.current.value}`);
    }
  };

  return (
    <div
      className={`app-header ${isSidebarVisible ? "with-sidebar" : ""}`}
      style={{
        maxWidth: "100vw",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        zIndex: 1000,
      }}
    >
      <div className="header-container">
        <table>
          <thead>
            <tr>
              <th>
                {/* Additional header content if needed */}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <h2 className="brand-title">
                  <Link to="/" style={{ textDecoration: "none" }}>
                    On-Screen
                  </Link>
                </h2>
              </td>
              <td>
                {/* Clicking the logo toggles the sidebar visibility */}
                <div
                  className="logo-container"
                  onClick={toggleSidebar}
                  style={{ cursor: "pointer" }}
                >
                  <img src="/images/logo-xsm.png" alt="Logo" className="logo" />
                </div>
              </td>
            </tr>
            {showSearch && (
              <tr>
                <td colSpan="2">
                  <form className="search-form" onSubmit={submitHandler}>
                    <input
                      type="search"
                      ref={searchRef}
                      placeholder="Search..."
                      className="search-input"
                    />
                  </form>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AppHeader;

