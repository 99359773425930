import React from "react";
import { Link } from "react-router-dom";

const Home = () => {
    return <div className="row">
        <div className="col-md-4 col-xs-10"></div>
        <div className="col-md-4 col-xs-16 text-center">
        <Link to="/login">
            <img src="/images/SED3.png" alt="SE3D" className="img img-thumbnail" />
        </Link>
            <h3>Company Name</h3>
            <p>About...</p>
        </div>
        <div className="col-md-4 col-xs-10"></div>
    </div>
}

export default Home;