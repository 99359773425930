// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import axios from 'axios';
// import EventCard from './EventCard';

// const EventList = () => {
//     const [events, setEvents] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//     const { tenant } = useParams(); // Get tenant ID from the URL

//     useEffect(() => {
//         const fetchEvents = async () => {
//             try {
//                 const url = `http://localhost:5000/api/${tenant}/events`;
//                 console.log('Fetching events from:', url); // Debugging log
//                 const response = await axios.get(url);
//                 console.log('API Response:', response.data); // Debugging log

//                 if (response.data.success) {
//                     setEvents(response.data.data || []);
//                 } else {
//                     setError(response.data.message || 'Failed to fetch events.');
//                 }
//             } catch (err) {
//                 console.error('Error fetching events:', err);
//                 setError(err.response?.data?.message || 'Failed to fetch events.');
//             } finally {
//                 setLoading(false);
//             }
//         };

//         fetchEvents();
//     }, [tenant]);

//     if (loading) return <p>Loading events...</p>;
//     if (error) return <p>Error: {error}</p>;

//     return (
//         <div className="container">
//             <div className="member-list" style={{ maxWidth: "100vw" }}>
//                 {events.length > 0 ? (
//                     events.map((event) => 
//                         <EventCard event={event} key={event.id} />
//                     )
//                 ) : (
//                     <p>No events found for this tenant.</p>
//                 )}
//             </div>
//         </div>
//     );
// };

// export default EventList;


import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import EventCard from './EventCard';

const EventList = () => {
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { tenant } = useParams();

    const baseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:5000';

    useEffect(() => {
        if (!tenant) {
            console.error("Tenant ID is missing!");
            setError("Tenant ID is missing.");
            setLoading(false);
            return;
        }

        const fetchEvents = async () => {
            try {
                const url = `${baseUrl}/api/${tenant}/events`;

                const response = await axios.get(url);

                console.error("API Response:", response.data); // Debugging step

                if (response.data.success) {
                    setEvents(response.data.data || []);
                } else {
                    throw new Error(response.data.message || 'Failed to fetch events.');
                }
            } catch (err) {
                console.error('Error fetching events:', err);
                setError(err.response?.data?.message || 'Failed to fetch events.');
            } finally {
                setLoading(false);
            }
        };

        fetchEvents();
    }, [tenant]);

    if (loading) return <p>Loading events...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <div className="event-list-container">
            {events.length > 0 ? (
                events.map((event) => <EventCard event={event} key={event.id} />)
            ) : (
                <p>No events available.</p>
            )}
        </div>
    );
};

export default EventList;


