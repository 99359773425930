
import React, { useState, useParams } from "react";
import { Link } from "react-router-dom";
import sidebar from "../data/sidebar"; // Import sidebar structure

const Sidebar = ({ tenantId }) => {
    const [isOpen, setIsOpen] = useState(false); // Manage sidebar open/collapse
    const [expandedMenu, setExpandedMenu] = useState({}); // Track expanded submenus

    // Toggle sidebar open/collapse
    const toggleSidebar = () => setIsOpen(!isOpen);

    // Toggle submenu open/collapse
    const toggleSubmenu = (index) => {
        setExpandedMenu((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    return (
        <div className={`sidebar ${isOpen ? "open" : "collapsed"}`}>

            <div className="icon-div">
                {isOpen ? 
                    <i className="bi bi-caret-left-fill"  onClick={toggleSidebar}></i> : <i className="bi bi-caret-right-fill"  onClick={toggleSidebar}></i>}
            </div>
            {/* Sidebar Menu */}
            <ul className="menu-list">
                {sidebar(tenantId).map((menuItem, index) => (
                    <li key={index} className="menu-item">
                        <div
                            className="menu-title"
                            onClick={() => toggleSubmenu(index)}
                            style={{ cursor: menuItem.childrens ? "pointer" : "default" }}
                        >
                            <i className={`bi ${menuItem.icon}`}></i>
                            {isOpen && <span>{menuItem.title}</span>}
                            {menuItem.childrens && isOpen && (
                                <i
                                    className={`bi ${
                                        expandedMenu[index] ? "bi-chevron-down" : "bi-chevron-right"
                                    } submenu-toggle-icon`}
                                    style={{ marginLeft: "auto" }}
                                ></i>
                            )}
                        </div>
                        {menuItem.childrens && expandedMenu[index] && (
                            <ul className="submenu-list">
                                {menuItem.childrens.map((child, idx) => (
                                    <li key={idx} className="submenu-item">
                                        <Link to={child.path.replace("${tenantId}", tenantId)}>
                                            <i className={`bi ${child.icon}`}></i>
                                            {isOpen && <span>{child.title}</span>}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Sidebar;

// import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import sidebar from "../data/sidebar"; // Import sidebar structure

// const Sidebar = ({ isOpen, toggleSidebar }) => {
//     const [expandedMenu, setExpandedMenu] = useState({});

//     // Toggle submenu open/collapse
//     const toggleSubmenu = (index) => {
//         setExpandedMenu((prevState) => ({
//             ...prevState,
//             [index]: !prevState[index],
//         }));
//     };

//     return (
//         <div className={`sidebar ${isOpen ? "open" : "collapsed"}`}>
//             <div className="icon-div">
//                 <i className={isOpen ? "bi bi-caret-left-fill" : "bi bi-caret-right-fill"} onClick={toggleSidebar}></i>
//             </div>
//             {/* Sidebar Menu */}
//             <ul className="menu-list">
//                 {sidebar.map((menuItem, index) => (
//                     <li key={index} className="menu-item">
//                         <div
//                             className="menu-title"
//                             onClick={() => toggleSubmenu(index)}
//                             style={{ cursor: menuItem.childrens ? "pointer" : "default" }}
//                         >
//                             <i className={`bi ${menuItem.icon}`}></i>
//                             {isOpen && <span>{menuItem.title}</span>}
//                             {menuItem.childrens && isOpen && (
//                                 <i
//                                     className={`bi ${
//                                         expandedMenu[index] ? "bi-chevron-down" : "bi-chevron-right"
//                                     } submenu-toggle-icon`}
//                                     style={{ marginLeft: "auto" }}
//                                 ></i>
//                             )}
//                         </div>
//                         {menuItem.childrens && expandedMenu[index] && (
//                             <ul className="submenu-list">
//                                 {menuItem.childrens.map((child, idx) => (
//                                     <li key={idx} className="submenu-item">
//                                         <Link to={child.path}>
//                                             <i className={`bi ${child.icon}`}></i>
//                                             {isOpen && <span>{child.title}</span>}
//                                         </Link>
//                                     </li>
//                                 ))}
//                             </ul>
//                         )}
//                     </li>
//                 ))}
//             </ul>
//         </div>
//     );
// };

// export default Sidebar;

