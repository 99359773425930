import React from "react";
import { useNavigate, useParams } from "react-router-dom";

const MemberCard = ({ member }) => {
    const { tenant } = useParams();
    const navigate = useNavigate();

    const handleCardClick = () => {
        navigate(`/${tenant}/memberDetail`, { state: { member } });
    };

    // const imageUrl = member.image && member.image.startsWith("http")
    // ? member.image
    // : `http://localhost:5000/public/tenants/${tenant}/members/${member.image}`;
    const imageUrl = member.image || "/user-icon.png";


    

    return (
        <div className="member-card" style={{ transform: 'scale(.65)' }}>
            <div className="card" onClick={handleCardClick} style={{ cursor: "pointer" }}>
                <img
                    src={imageUrl}
                    alt={member.firstname}
                    className="card-img-top"
                    onError={(e) => e.target.src = "/user-icon.png"} // Fallback image
                />
                <div className="card-body">
                    <h5 className="card-title">{member.firstname} {member.lastname}</h5>
                    <p className="card-text">{member.email}</p>
                    <p className="card-text">{member.phone}</p>
                </div>
            </div>
        </div>
    );
};

export default MemberCard;
