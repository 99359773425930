
import React, { useState } from 'react';
import './ImageUploadModal.css';

function ImageUploadModal({ onUploadComplete, onClose }) {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles(files);
    // setFiles([...e.target.files]);

    // Generate preview URLs
    const previews = files.map((file) => URL.createObjectURL(file));
    setPreviewImages(previews);

    // Pass selected images to the parent component
    if (onUploadComplete) {
      onUploadComplete(files); // Pass selected files to the parent (MemberAdd)
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h3>Select Images</h3>
        {/* <input
          type="file"
          multiple
          accept="image/*"
          onChange={handleFileChange}
          style={{ display: 'block', margin: '20px 0' }}
        /> */}
        <input type="file" multiple accept="image/*" onChange={handleFileChange} />
        
        <div className="image-preview">
          {previewImages.map((src, index) => (
            <img key={index} src={src} alt="Preview" className="preview-image" />
          ))}
        </div>
        <div className="button-group">
          <button onClick={onClose} className="btn btn-danger">
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

export default ImageUploadModal;
