import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const MemberDetail = () => {
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    // const [state, setState] = useState('');
    const [phone, setPhone] = useState('');
    const [registerStatus, setRegisterStatus] = useState('');
    const [waiverUrl, setWaiverUrl] = useState('');
    const navigate = useNavigate();
    const { tenant } = useParams();
    const { state } = useLocation(); // Access passed member details
    
    const [member, setMember] = useState(state?.member || {});
    const baseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:5000';

    const updatemember = async (e) => {
        e.preventDefault();
        try {
          const response = await axios.post(
            `${baseUrl}/api/${tenant}/updatemember`,
            {
              email,
              firstname: firstName,
              lastname: lastName,
              address1,
              address2,
              city,
              state,
              phone,
            }
          );
    
          if (response.data.message) {
            setRegisterStatus(response.data.message);
          } else {
            setRegisterStatus('MEMBER ACCOUNT CREATED SUCCESSFULLY');
          }
    
          // Navigate to waiver or members page
          if (waiverUrl) {
            window.location.href = waiverUrl;
          } else {
            navigate(`/${tenant}/members`);
          }
        } catch (error) {
          console.error('Error registering member:', error);
          setRegisterStatus('Error registering member. Please try again.');
        }
      };

    const handleCancel = () => {
        navigate(`/${tenant}/members`); // Navigate back to the member list
    };

    const handleSave = () => {
        updatemember();
        console.log("Saved member details:", member);
        alert("Member details saved!");
        navigate(`/${tenant}/members`); // Navigate back after saving
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setMember((prev) => ({ ...prev, [name]: value }));
    };

    return (
        <div className="container">
            <h2>Member Details</h2>
            <form onSubmit={updatemember}>
                <div className="form-group">
                    <label htmlFor="firstname">First Name</label>
                    <input
                        type="text"
                        className="form-control"
                        id="firstname"
                        name="firstname"
                        value={member.firstname || ""}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="lastname">Last Name</label>
                    <input
                        type="text"
                        className="form-control"
                        id="lastname"
                        name="lastname"
                        value={member.lastname || ""}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        value={member.email || ""}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="phone">Phone</label>
                    <input
                        type="text"
                        className="form-control"
                        id="phone"
                        name="phone"
                        value={member.phone || ""}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="address1">Address 1</label>
                    <input
                        type="text"
                        className="form-control"
                        id="address1"
                        name="address1"
                        value={member.address1 || ""}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="address2">Address 2</label>
                    <input
                        type="text"
                        className="form-control"
                        id="address2"
                        name="address2"
                        value={member.address2 || ""}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="city">City</label>
                    <input
                        type="text"
                        className="form-control"
                        id="city"
                        name="city"
                        value={member.city || ""}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="state">State</label>
                    <input
                        type="text"
                        className="form-control"
                        id="state"
                        name="state"
                        value={member.state || ""}
                        onChange={handleInputChange}
                    />
                </div>
                <button type="button" className="btn btn-secondary" onClick={handleCancel}>
                    Cancel
                </button>
                <button type="button" className="btn btn-primary" onClick={handleSave}>
                    Save
                </button>
            </form>
        </div>
    );
};

export default MemberDetail;

