
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import CommentBox from "./CommentBox";
import "../media/ImageUploadModal.css";

const extractMentions = (text) => {
  const mentionRegex = /@(\w+)/g;
  const mentions = [];
  let match;
  while ((match = mentionRegex.exec(text)) !== null) {
    mentions.push(match[1]);
  }
  return mentions;
};

const TaskAdd = ({ eventItemId, onClose }) => {
  const { tenant, eventId } = useParams();
  const navigate = useNavigate();
  const [tasks, setTasks] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [comment, setComment] = useState("");
  const [clearComment, setClearComment] = useState(false);
  const [loading, setLoading] = useState(true);

  const baseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:5000';

  useEffect(() => {
    if (!eventId) {
      console.error("❌ eventId is undefined! Cannot fetch tasks.");
      return;
    }
    const fetchTasks = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/api/${tenant}/events/${eventId}/tasks`
        );
        if (response.data.success) {
          setTasks(response.data.tasks || []);
        }
      } catch (error) {
        console.error("Error fetching tasks:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchTasks();
  }, [tenant, eventId, eventItemId]);

  const handleSubmit = async () => {
    if (!title) return;
    const taskData = {
      title,
      description,
      date,
      time,
      comment,
      eventId,
      eventItemId: eventItemId || null,
    };
    try {
      const response = await axios.post(
        `${baseUrl}/api/${tenant}/events/${eventId}/tasks`,
        taskData
      );
      if (response.data.success) {
        setTasks([...tasks, response.data.task]);
        setTitle("");
        setDescription("");
        setDate("");
        setTime("");
        setComment("");
        setClearComment(true);
        setTimeout(() => setClearComment(false), 100);

        const mentions = extractMentions(taskData.comment);
        if (mentions.length > 0) {
          axios
            .post(`${baseUrl}/api/notify/notifyTaskMentions`, {
              taskId: response.data.task.id,
              eventId,
              tenant,
              mentions,
              comment: taskData.comment,
            })
            .then(() => {
              console.log("Notifications sent for mentions:", mentions);
            })
            .catch((err) => {
              console.error("Error sending notifications:", err);
            });
        }
      }
    } catch (error) {
      console.error("Error adding task:", error);
    }
  };

  const handleDelete = async (taskId) => {
    try {
      await axios.delete(`${baseUrl}/api/${tenant}/tasks/${taskId}`);
      setTasks((prevTasks) => prevTasks.filter((task) => task.id !== taskId));
    } catch (error) {
      console.error("Error deleting task:", error);
    }
  };

  return (
    <div className="modal-overlay fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      {/* Constrained modal content */}
      <div className="modal-content bg-white p-4 rounded shadow-md flex flex-col max-h-[80vh] w-full max-w-lg">
        {/* Header */}
        <div className="mb-4">
          <h2 className="text-xl font-bold">Task</h2>
          <p>Event ID: {eventId || "❌ Missing Event ID"}</p>
          <p>Tenant ID: {tenant}</p>
        </div>
        {/* Scrollable Task List Container */}
        <div className="mb-4 flex-none">
          <h2 className="text-lg font-bold mb-2">Tasks</h2>
          <div className="task-list-container border p-2 space-y-2">
            {loading ? (
              <p style={{ color: "black" }}>Loading tasks...</p>
            ) : tasks.length > 0 ? (
              tasks.map((task, index) => (
                <div
                  key={`${task.id}-${index}`}
                  className="task-card border rounded shadow flex flex-col text-sm"
                  style={{ color: "black" }}
                >
                  <p className="font-bold">{task.title || "No title"}</p>
                  <p>{task.description || "No description"}</p>
                  <p>{task.date || "No date"}</p>
                  <p>{task.time || "No time"}</p>
                  <p>{task.comment || "No comment"}</p>
                  <button
                    className="text-red-500 self-end mt-1"
                    onClick={() => handleDelete(task.id)}
                  >
                    ❌ Delete
                  </button>
                </div>
              ))
            ) : (
              <p style={{ color: "black" }}>No tasks found.</p>
            )}
          </div>
        </div>
        {/* Form Section */}
        <div className="form-group">
          <label htmlFor="title">Task Title:</label>
          <input
            type="text"
            name="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Task Title"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="description">Task Description:</label>
          <textarea
            placeholder="Task Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="border p-2 w-full"
          />
        </div>
        <div className="form-group">
          <label htmlFor="comment">Add A Comment:</label>
          <CommentBox
            placeholder="Add a comment..."
            onCommentChange={setComment}
            clearComment={clearComment}
          />
        </div>
        <div className="space-y-4">
          {/* <input
            type="text"
            placeholder="Task Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="border p-2 w-full"
            style={{ color: "black" }}
          />
          <textarea
            placeholder="Task Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="border p-2 w-full"
          />
          <CommentBox
            placeholder="Add a comment..."
            onCommentChange={setComment}
            clearComment={clearComment}
          /> */}
          <input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            className="border p-2 w-full"
          />
          <input
            type="time"
            value={time}
            onChange={(e) => setTime(e.target.value)}
            className="border p-2 w-full"
          />
          <div className="flex justify-between mt-4">
            <button onClick={() => navigate(-1)} className="btn btn-secondary">
              Back
            </button>
            <button onClick={handleSubmit} className="btn btn-secondary">
              Add Task
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskAdd;
