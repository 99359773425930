
// import React, { useState, useEffect } from "react";
// import { useNavigate, useParams, useLocation } from "react-router-dom";
// import axios from "axios";
// import ImageUploadModal from "../media/ImageUploadModal";
// import TaskAdd from "./TaskAdd";

// const EventDetail = () => {
//     const [event, setEvent] = useState(null);
//     const [registerStatus, setRegisterStatus] = useState('');
//     const [images, setImages] = useState([]);
//     const location = useLocation();
//     const [eventImages, setEventImages] = useState([]);
//     const [eventItems, setEventItems] = useState([]);
//     const [eventItemImages, setEventItemImages] = useState({});
//     const [loading, setLoading] = useState(true);
//     const navigate = useNavigate();
//     const { tenant, eventId } = useParams();

//     useEffect(() => {
//         const fetchEventData = async () => {
//             try {
//                 setLoading(true);
//                 const [eventRes, imagesRes, itemsRes] = await Promise.all([
//                     axios.get(`http://localhost:5000/api/${tenant}/events/${eventId}`),
//                     axios.get(`http://localhost:5000/api/${tenant}/events/${eventId}/images`),
//                     axios.get(`http://localhost:5000/api/${tenant}/events/${eventId}/items`)
//                 ]);

//                 setEvent(eventRes.data.event || {});
//                 setEventImages(imagesRes.data.images || []);
//                 setEventItems(itemsRes.data.items || []);

//                 // Fetch item images **in parallel**
//                 const itemImagesRequests = itemsRes.data.items.map(item => 
//                     axios.get(`http://localhost:5000/api/${tenant}/events/${eventId}/items/${item.id}/images`)
//                         .then(res => ({ [item.id]: res.data.images || [] }))
//                         .catch(() => ({ [item.id]: [] })) // Handle errors gracefully
//                 );

//                 const itemImagesResults = await Promise.all(itemImagesRequests);
//                 const itemImagesMap = itemImagesResults.reduce((acc, curr) => ({ ...acc, ...curr }), {});
//                 setEventItemImages(itemImagesMap);
                
//             } catch (error) {
//                 console.error("Error loading event data:", error);
//             } finally {
//                 setLoading(false);
//             }
//         };

//         fetchEventData();
//     }, [tenant, eventId]);
    

//     const handleItemClick = (itemId) => {
//         navigate(`/${tenant}/${eventId}/${itemId}/eventItemDetail`);
//     };


//     const handleCancel = () => {
//         navigate(`/${tenant}/events`); // Navigate back to the event list
//     };

//     const handleTask = () => {
//         if (!eventId) {
//             console.error("❌ eventId is missing! Cannot open TaskAdd.");
//             return;
//         }
//         navigate(`/${tenant}/${eventId}/task`);
//     };

//     const handleItem = () => {
//         if (!eventId) {
//             console.error("❌ eventId is missing! Cannot open ItemAdd.");
//             return;
//         }
//         navigate(`/${tenant}/${eventId}/eventItemAdd`);
//     };

//     const handleImageUpload = async (files) => {
//         if (files.length === 0) return;
    
//         const formData = new FormData();
//         files.forEach((file) => formData.append("files", file));
    
//         try {
//             const response = await axios.post(
//                 `http://localhost:5000/api/uploads/${tenant}/${eventId}/uploadimage`,
//                 formData,
//                 { headers: { "Content-Type": "multipart/form-data" } }
//             );
    
//             if (response.data.success) {
//                 console.log("Images uploaded successfully");
//                 setEventImages([...eventImages, ...response.data.files]);
//             }
//         } catch (error) {
//             console.error("Error uploading images:", error);
//         }
//     };

//     const updateEvent = async () => {
//         try {
//             const response = await axios.post(`http://localhost:5000/api/${tenant}/updateevent`, {
//                 id: eventId,
//                 ...event,
//             });
//             setRegisterStatus(response.data.message || "Event updated successfully");
//         } catch (error) {
//             setRegisterStatus("Error updating event.");
//         }
//     };

//     const handleInputChange = (e) => {
//         setEvent((prev) => ({ ...prev, [e.target.name]: e.target.value }));
//     };

//     if (loading) return <p>Loading event details...</p>;

//     return (
//         <div className="container">
//             {event ? (
//             <>
//             <h2>Event Details</h2>
//             <form onSubmit={updateEvent}>
//                 <div className="form-group">
//                     <label htmlFor="name">Event Name</label>
//                     <input
//                         type="text"
//                         className="form-control"
//                         id="name"
//                         name="name"
//                         value={event.name || ""}
//                         onChange={handleInputChange}
//                     />
//                 </div>
//                 <div className="form-group">
//                     <label htmlFor="description">Description</label>
//                     <input
//                         type="text"
//                         className="form-control"
//                         id="description"
//                         name="description"
//                         value={event.description || ""}
//                         onChange={handleInputChange}
//                     />
//                 </div>
//                 <div className="form-group">
//                     <label htmlFor="type">Type</label>
//                     <input
//                         type="text"
//                         className="form-control"
//                         id="type"
//                         name="type"
//                         value={event.type || ""}
//                         onChange={handleInputChange}
//                     />
//                 </div>
//                 <div className="form-group">
//                     <label htmlFor="startdate">Start Date</label>
//                     <input
//                         type="date"
//                         className="form-control"
//                         id="startdate"
//                         name="startdate"
//                         value={event.startdate ? event.startdate.split("T")[0] : ""}
//                         onChange={handleInputChange}
//                     />
//                 </div>
//                 <div className="form-group">
//                     <label htmlFor="enddate">End Date</label>
//                     <input
//                         type="date"
//                         className="form-control"
//                         id="enddate"
//                         name="enddate"
//                         value={event.enddate ? event.enddate.split("T")[0] : ""}
//                         onChange={handleInputChange}
//                     />
//                 </div>

//                     {eventImages.length > 0 ? (
//                         <div className="image-gallery">
//                             {eventImages.map((img, index) => (
//                                 <img
//                                     key={index}
//                                     src={img.path}
//                                     alt="Event"
//                                     className="event-thumbnail"
//                                     onError={(e) => e.target.src = "/event.png"}
//                                 />
//                             ))}
//                         </div>
//                     ) : (
//                         <p>No images available for this event.</p>
//                     )}

//                     {eventItems.length > 0 ? (
//                         <div className="event-items-container">
//                             {eventItems.map((item) => (
//                                 <div key={item.id} className="event-item">
//                                     <h4>{item.name}</h4>
//                                     {eventItemImages[item.id]?.length > 0 ? (
//                                         <div className="item-images">
//                                             {eventItemImages[item.id].map((img, idx) => (
//                                                 <img
//                                                     key={idx}
//                                                     src={img.path}
//                                                     alt="Item"
//                                                     className="item-thumbnail"
//                                                     onClick={() => handleItemClick(item.id)}
//                                                     onError={(e) => e.target.src = "/event.png"}
//                                                 />
//                                             ))}
//                                         </div>
//                                     ) : (
//                                         <p>No images for this item.</p>
//                                     )}
//                                 </div>
//                             ))}
//                         </div>
//                     ) : (
//                         <p>No items available for this event.</p>
//                     )}
                
//                 <button type="button" className="btn btn-secondary" onClick={handleCancel}>
//                     Cancel
//                 </button>
//                 <button type="button" className="btn btn-secondary" onClick={handleItem}>
//                     Add Item
//                 </button>
//                 <button type="button" className="btn btn-secondary" onClick={handleTask}>
//                     Task
//                 </button>
//                 <button type="button" className="btn btn-primary" onClick={updateEvent}>
//                     Save
//                 </button>
//                 <p>{registerStatus}</p>
//             </form>
//             </>
//              ) : (
//                  <p>Loading event details...</p>
//              )}
//         </div>
//     );
// };

// export default EventDetail;

import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import ImageUploadModal from "../media/ImageUploadModal";
import TaskAdd from "./TaskAdd";

const EventDetail = () => {
  const [event, setEvent] = useState(null);
  const [registerStatus, setRegisterStatus] = useState('');
  const [eventImages, setEventImages] = useState([]);
  const [eventItems, setEventItems] = useState([]);
  const [eventItemImages, setEventItemImages] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { tenant, eventId } = useParams();
  const baseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:5000';

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        setLoading(true);
        const [eventRes, imagesRes, itemsRes] = await Promise.all([
          axios.get(`${baseUrl}/api/${tenant}/events/${eventId}`),
          axios.get(`${baseUrl}/api/${tenant}/events/${eventId}/images`),
          axios.get(`${baseUrl}/api/${tenant}/events/${eventId}/items`)
        ]);

        setEvent(eventRes.data.event || {});
        setEventImages(imagesRes.data.images || []);
        setEventItems(itemsRes.data.items || []);

        // For each event item, fetch its images
        const itemImagesRequests = itemsRes.data.items.map(item =>
          axios.get(`${baseUrl}/api/${tenant}/events/${eventId}/items/${item.id}/images`)
            .then(res => ({ [item.id]: res.data.images || [] }))
            .catch(() => ({ [item.id]: [] }))
        );
        const itemImagesResults = await Promise.all(itemImagesRequests);
        const itemImagesMap = itemImagesResults.reduce((acc, curr) => ({ ...acc, ...curr }), {});
        setEventItemImages(itemImagesMap);
      } catch (error) {
        console.error("Error loading event data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEventData();
  }, [tenant, eventId]);

  const handleItemClick = (itemId) => {
    navigate(`/${tenant}/${eventId}/${itemId}/eventItemDetail`);
  };

  const handleCancel = () => {
    navigate(`/${tenant}/events`);
  };

  const handleTask = () => {
    if (!eventId) {
      console.error("❌ eventId is missing! Cannot open TaskAdd.");
      return;
    }
    navigate(`/${tenant}/${eventId}/task`);
  };

  const handleItem = () => {
    if (!eventId) {
      console.error("❌ eventId is missing! Cannot open ItemAdd.");
      return;
    }
    navigate(`/${tenant}/${eventId}/eventItemAdd`);
  };

  const updateEvent = async () => {
    try {
      const response = await axios.post(`${baseUrl}/api/${tenant}/updateevent`, {
        id: eventId,
        ...event,
      });
      setRegisterStatus(response.data.message || "Event updated successfully");
    } catch (error) {
      setRegisterStatus("Error updating event.");
    }
  };

  const handleInputChange = (e) => {
    setEvent((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  if (loading) return <p>Loading event details...</p>;

  return (
    <div className="container">
      {event ? (
        <>
          <h2>Event Details</h2>
          <form onSubmit={updateEvent}>
            {/* Event basic information */}
            <div className="form-group">
              <label htmlFor="name">Event Name</label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={event.name || ""}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="description">Description</label>
              <input
                type="text"
                className="form-control"
                id="description"
                name="description"
                value={event.description || ""}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="type">Type</label>
              <input
                type="text"
                className="form-control"
                id="type"
                name="type"
                value={event.type || ""}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="startdate">Start Date</label>
              <input
                type="date"
                className="form-control"
                id="startdate"
                name="startdate"
                value={event.startdate ? event.startdate.split("T")[0] : ""}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="enddate">End Date</label>
              <input
                type="date"
                className="form-control"
                id="enddate"
                name="enddate"
                value={event.enddate ? event.enddate.split("T")[0] : ""}
                onChange={handleInputChange}
              />
            </div>

            {/* Event images displayed as cards */}
            <h3>Event Images</h3>
            <div className="card-deck" style={{ display: "flex", flexWrap: "wrap" }}>
              {eventImages.length > 0 ? (
                eventImages.map((img, index) => (
                  <div className="card" key={index} style={{ width: "18rem", margin: "0.5rem" }}>
                    <img
                      src={img.path.startsWith("http") ? img.path : `${baseUrl}${img.path}`}
                      className="card-img-top"
                      alt="Event"
                      onError={(e) => e.target.src = "/event.png"}
                    />
                  </div>
                ))
              ) : (
                <p>No images available for this event.</p>
              )}
            </div>

            {/* Event items rendered as cards */}
            <h3>Event Items</h3>
            <div className="card-deck" style={{ display: "flex", flexWrap: "wrap" }}>
              {eventItems.length > 0 ? (
                eventItems.map((item) => (
                  <div key={item.id} className="card" style={{ width: "18rem", margin: "0.5rem" }}>
                    <div className="card-body">
                      <h5 className="card-title">{item.name}</h5>
                      <p className="card-text">{item.description}</p>
                    </div>
                    {eventItemImages[item.id] && eventItemImages[item.id].length > 0 ? (
                      <div className="card-img-top" style={{ display: "flex", flexWrap: "wrap" }}>
                        {eventItemImages[item.id].map((img, idx) => (
                          <a key={idx} href={`/${tenant}/${eventId}/${item.id}/eventItemDetail`}>
                            <img
                              src={img.path.startsWith("http") ? img.path : `${baseUrl}${img.path}`}
                              alt="Event Item"
                              style={{ width: "100px", margin: "0.5rem" }}
                              onError={(e) => e.target.src = "/event.png"}
                            />
                          </a>
                        ))}
                      </div>
                    ) : (
                      <div className="card-img-top">
                        <p className="text-center">No images for this item.</p>
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <p>No items available for this event.</p>
              )}
            </div>

            {/* Action buttons */}
            <div className="button-group" style={{ marginTop: "1rem" }}>
              <button type="button" className="btn btn-secondary" onClick={handleCancel}>
                Cancel
              </button>
              <button type="button" className="btn btn-secondary" onClick={handleItem}>
                Add Item
              </button>
              <button type="button" className="btn btn-secondary" onClick={handleTask}>
                Task
              </button>
              <button type="button" className="btn btn-primary" onClick={updateEvent}>
                Save
              </button>
            </div>
            <p>{registerStatus}</p>
          </form>
        </>
      ) : (
        <p>Loading event details...</p>
      )}
    </div>
  );
};

export default EventDetail;
