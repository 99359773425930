


import React, { useRef, useState, useEffect } from 'react';
import './CameraComponent.css';

const CameraComponent = ({ onImageCaptured, onClose }) => {
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const [isCameraOn, setIsCameraOn] = useState(false);

    useEffect(() => {
        checkCameraPermission();

        // Clean up when the component unmounts
        return () => {
            stopCamera();
        };
    }, []);

    const checkCameraPermission = async () => {
        try {
            const permissions = await navigator.permissions.query({ name: 'camera' });
            if (permissions.state === 'denied') {
                alert('Camera access is denied. Please enable it in your browser settings.');
            }
        } catch (error) {
            console.error('Error checking camera permissions:', error);
        }
    };

    const startCamera = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            if (videoRef.current) {
                videoRef.current.srcObject = stream;
                setIsCameraOn(true);
            }
        } catch (error) {
            console.error('Error accessing camera:', error);
            alert('Unable to access the camera. Please check your device settings.');
        }
    };

    const stopCamera = () => {
        const stream = videoRef.current?.srcObject;
        const tracks = stream?.getTracks();
        tracks?.forEach((track) => track.stop());
        setIsCameraOn(false);
    };

    const captureImage = () => {
        if (canvasRef.current && videoRef.current) {
            const context = canvasRef.current.getContext('2d');
            canvasRef.current.width = videoRef.current.videoWidth;
            canvasRef.current.height = videoRef.current.videoHeight;
            context.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);
            const capturedImage = canvasRef.current.toDataURL('image/png');
            onImageCaptured(capturedImage); // Pass captured image back to parent
            stopCamera();
            onClose(); // Close the camera after capturing
        }
    };

    return (
        <div className="camera-component">
            <video
                ref={videoRef}
                autoPlay
                playsInline
                className="camera-video"
            />
            <canvas ref={canvasRef} style={{ display: 'none' }} />
            <div className="camera-controls">
                {!isCameraOn ? (
                    <button className="btn" onClick={startCamera}>Start Camera</button>
                ) : (
                    <button className="btn" onClick={stopCamera}>Stop Camera</button>
                )}
                {isCameraOn && <button className="btn" onClick={captureImage}>Capture Image</button>}
                <button className="btn" onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default CameraComponent;
