
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import ImageUploadModal from '../media/ImageUploadModal';
import CameraComponent from '../media/CameraComponent';

function MemberAdd() {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [phone, setPhone] = useState('');
  const [registerStatus, setRegisterStatus] = useState('');
  const [waiverUrl, setWaiverUrl] = useState('');
  const [imagePath, setImagePath] = useState(null);
  const [showCamera, setShowCamera] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const navigate = useNavigate();
  const { tenant } = useParams();

  const baseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:5000';

  useEffect(() => {
    const fetchWaiverUrl = async () => {
      try {
        const response = await axios.get('/api/tenant-waiver');
        setWaiverUrl(response.data.waiver || '');
      } catch (error) {
        console.error('Error fetching waiver URL:', error);
      }
    };
    fetchWaiverUrl();
  }, [tenant]);

  const handleImageCaptured = (capturedImage) => {
    setImagePath(capturedImage);
    setShowCamera(false);
  };

  // Handle image selection from ImageUploadModal
  const handleUploadComplete = (files) => {
    if (files.length > 0) {
      setSelectedFile(files[0]); // Store first selected file
      setImagePath(URL.createObjectURL(files[0])); // Show preview
    }
    setIsImageModalOpen(false);
  };

  // Upload image (optional) and register the member
  const registerMember = async (e) => {
    e.preventDefault();
    let uploadedImagePath = null;

    // Attempt to upload the image
    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);
      
      try {
        const uploadResponse = await axios.post(
          `${baseUrl}/api/${tenant}/uploadmember`,
          formData,
          { headers: { 'Content-Type': 'multipart/form-data' } }
        );
        if (uploadResponse.data.success) {
          uploadedImagePath = uploadResponse.data.filePath;
        }
      } catch (error) {
        console.warn('Image upload failed, proceeding without an image.');
      }
    }

    // Proceed with member registration
    try {
      const response = await axios.post(
        `${baseUrl}/api/members/${tenant}/registermember`,
        {
          email,
          firstname: firstName,
          lastname: lastName,
          address1,
          address2,
          city,
          state,
          zip,
          phone,
          image: uploadedImagePath ? `${baseUrl}${uploadedImagePath}` : null, // Image can be null
        }
        
      );

      if (response.data.success) {
        navigate(`/${tenant}/members`);
      } else {
        console.error('Registration failed:', response.data.message);
      }
    } catch (error) {
      console.error('Error registering member:', error);
    }
  };

  return (
    <div className="main-content">
    <div className="col-md-1">
      <div className="card">
        <h4>Create Account</h4>
        <div className="card-body">
          <form onSubmit={registerMember}>
            <div className="form-group">
              <label htmlFor="firstName">First Name:</label>
              <input
                type="text"
                className="form-control"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="First Name"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="lastName">Last Name:</label>
              <input
                type="text"
                className="form-control"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Last Name"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="address1">Address 1:</label>
              <input
                type="text"
                className="form-control"
                value={address1}
                onChange={(e) => setAddress1(e.target.value)}
                placeholder="Address 1"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="address2">Address 2:</label>
              <input
                type="text"
                className="form-control"
                value={address2}
                onChange={(e) => setAddress2(e.target.value)}
                placeholder="Address 2"
              />
            </div>
            <div className="form-group">
              <label htmlFor="city">City:</label>
              <input
                type="text"
                className="form-control"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                placeholder="City"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="state">State:</label>
              <input
                type="text"
                className="form-control"
                value={state}
                onChange={(e) => setState(e.target.value)}
                placeholder="State"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="zip">Zip:</label>
              <input
                type="text"
                className="form-control"
                value={zip}
                onChange={(e) => setZip(e.target.value)}
                placeholder="Zip"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email Address:</label>
              <input
                type="email"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your Email Address"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="phone">Phone:</label>
              <input
                type="text"
                className="form-control"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="Enter your Phone Number"
                required
              />
            </div>
            <div>
              {imagePath ? (
                <div>
                  <img
                    src={imagePath}
                    alt="Captured"
                    style={{ width: '100px', marginBottom: '10px' }}
                  />
                  <button
                    type="button"
                    className="btn btn-secondary me-2"
                    onClick={() => setShowCamera(true)}
                  >
                    Retake Photo
                  </button>
                </div>
              ) : (
                <button
                  type="button"
                  className="btn btn-secondary me-2"
                  onClick={() => setShowCamera(true)}
                >
                  Take Photo
                </button>
              )}
              {imagePath ? (
              <div>
                {/* <img src={imagePath} alt="Preview" style={{ width: '100px' }} /> */}
                <button type="button" className="btn btn-secondary me-2" onClick={() => setIsImageModalOpen(true)}>Change Photo</button>
              </div>
            ) : (
              <button type="button" className="btn btn-secondary me-2" onClick={() => setIsImageModalOpen(true)}>Upload Photo</button>
            )}
            </div>
            <button type="submit" className="btn btn-primary mt-3">
              Create Account
            </button>
          </form>
          {showCamera && (
            <CameraComponent
              onImageCaptured={handleImageCaptured}
              onClose={() => setShowCamera(false)}
            />
          )}
          {isImageModalOpen && (
            <ImageUploadModal
              tenantId={tenant}
              imageName={`${Date.now()}_${tenant}_${firstName}${lastName}`}
              onUploadComplete={handleUploadComplete}
              onClose={() => setIsImageModalOpen(false)}
            />
          )}
          {registerStatus && <p className="mt-3">{registerStatus}</p>}
        </div>
      </div>
    </div>
    </div>
  );
}

export default MemberAdd;

