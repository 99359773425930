import React, { useState, useEffect } from "react";
import axios from "axios";

const EventHub = ({ tenant }) => {
    const [events, setEvents] = useState([]);
    const [logo, setLogo] = useState("");
    const [background, setBackground] = useState(null);

    const baseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:5000';

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const response = await axios.get(`${baseUrl}/api/${tenant}/events/actions`);
                if (response.data.success) {
                    setEvents(response.data.actions);
                }
            } catch (error) {
                console.error("Error fetching event actions:", error);
            }
        };

        const fetchLogo = async () => {
            try {
                const response = await axios.get(`${baseUrl}/api/${tenant}/logo`);
                if (response.data.success) {
                    setLogo(response.data.logo);
                }
            } catch (error) {
                console.error("Error fetching tenant logo:", error);
            }
        };

        fetchEvents();
        fetchLogo();
    }, [tenant]);

    return (
        <div className="event-hub">
            {background && <img src={background} alt="Background" className="background-image" />}
            <header>
                {logo && <img src={logo} alt="Tenant Logo" className="tenant-logo" />}
                <h1>Event Hub</h1>
            </header>
            <div className="event-list">
                {events.map(event => (
                    <div key={event.id} className="event-card">
                        <h3>{event.name}</h3>
                        <p>{event.description}</p>
                        <a href={event.url} target="_blank" rel="noopener noreferrer">Go to Event</a>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default EventHub;
