
import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useParams, useNavigate } from 'react-router-dom';

function UserAdd() {
  const { tenant } = useParams(); // Extract tenantId from the URL
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [registerStatus, setRegisterStatus] = useState('');

  const navigate = useNavigate();
  const notifySuccess = (message) => toast.success(message);
  const notifyError = (message) => toast.error(message);

  const baseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:5000';

  const registerUser = (e) => {
    e.preventDefault();
    axios
      .post(`${baseUrl}/api/tenants/${tenant}/registeruser`, {
        username,
        email,
        firstname: firstName,
        lastname: lastName,
        phone,
        password,
      })
      .then((response) => {
        // if (response.data.message) {
        if (response.status === 201) {
          notifyError(response.data.message);
        } else {
          notifySuccess('Member account created successfully!');
          setUsername('');
          setEmail('');
          setFirstName('');
          setLastName('');
          setPhone('');
          setPassword('');
        }
      })
      .catch((error) => {
        console.error(error);
        notifyError('An error occurred while creating the account.');
      });
  };

  const handleDashboard = () => {
    navigate(`/${tenant}/dashboard`);
  };
  

return (
  <div className="owner-add-container">
      <div className="card">
          <h4 className="card-title">Create Account</h4>
          <div className="card-body">
              <form onSubmit={registerUser} className="form">
                  <div className="form-group">
                      <label htmlFor="username">Username</label>
                      <input
                        className="textInput"
                        type="text"
                        name="username"
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder="Username"
                        required
                      />
                  </div>
                  <div className="form-group">
                      <label htmlFor="firstName">First Name</label>
                      <input
                        className="textInput"
                        type="text"
                        name="firstName"
                        onChange={(e) => setFirstName(e.target.value)}
                        placeholder="First Name"
                        required
                      />
                  </div>
                  <div className="form-group">
                      <label htmlFor="lastName">Last Name</label>
                      <input
                        className="textInput"
                        type="text"
                        name="lastName"
                        onChange={(e) => setLastName(e.target.value)}
                        placeholder="Last Name"
                        required
                      />
                  </div>
                  <div className="form-group">
                      <label htmlFor="email">Email Address</label>
                      <input
                        className="textInput"
                        type="text"
                        name="email"
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter your Email Address"
                        required
                      />
                  </div>
                  <div className="form-group">
                      <label htmlFor="phone">Phone</label>
                      <input
                        className="textInput"
                        type="text"
                        name="phone"
                        onChange={(e) => setPhone(e.target.value)}
                        placeholder="Enter your Phone Number"
                        required
                      />
                  </div>
                  <div className="form-group">
                            <label htmlFor="password">Password</label>
                            <input
                                type="password"
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Password"
                                required
                            />
                        </div>
                  <button onClick={handleDashboard} type="button" className="btn btn-secondary">
                      Back
                  </button>
                  <button type="submit" className="btn btn-primary">
                      Create User
                  </button>
                  {/* {registerStatus && <p className="status-message">{registerStatus}</p>} */}
              </form>
          </div>
      </div>
  </div>
);

  // return (
  //   <div className="container">
  //     <div className="loginForm">
  //       <form>
  //         <h4>Create User for Tenant: {tenantId}</h4>
  //         <label htmlFor="username">Username</label>
  //         <input
  //           className="textInput"
  //           type="text"
  //           name="username"
  //           onChange={(e) => setUsername(e.target.value)}
  //           placeholder="Username"
  //           required
  //         />
  //         <label htmlFor="firstName">First Name</label>
  //         <input
  //           className="textInput"
  //           type="text"
  //           name="firstName"
  //           onChange={(e) => setFirstName(e.target.value)}
  //           placeholder="First Name"
  //           required
  //         />
  //         <label htmlFor="lastName">Last Name</label>
  //         <input
  //           className="textInput"
  //           type="text"
  //           name="lastName"
  //           onChange={(e) => setLastName(e.target.value)}
  //           placeholder="Last Name"
  //           required
  //         />
  //         <label htmlFor="email">Email Address</label>
  //         <input
  //           className="textInput"
  //           type="text"
  //           name="email"
  //           onChange={(e) => setEmail(e.target.value)}
  //           placeholder="Enter your Email Address"
  //           required
  //         />
  //         <label htmlFor="phone">Phone</label>
  //         <input
  //           className="textInput"
  //           type="text"
  //           name="phone"
  //           onChange={(e) => setPhone(e.target.value)}
  //           placeholder="Enter your Phone Number"
  //           required
  //         />
  //         <input
  //           className="button"
  //           type="submit"
  //           onClick={registerUser}
  //           value="Create an account"
  //         />
  //       </form>
  //     </div>
  //   </div>
  // );
}

export default UserAdd;

