// import React from "react";
// import { useNavigate, useParams } from "react-router-dom";

// const EventCard = ({ event }) => {
//     const { tenant } = useParams();
//     const navigate = useNavigate();

//     const handleCardClick = () => {
//         // navigate(`/${tenant}/eventDetail`, { state: { event } });
//         navigate(`/${tenant}/event`, { state: { event } });
//     };

//     return (
//         <div className="member-card" style={{ transform: 'scale(.65)' }}>
//          {/* <div className="col-md-4"> */}
//             <div className="card" onClick={handleCardClick} style={{ cursor: "pointer" }}>
//                 <img
//                     src={"http://www.w3.org/2000/svg" }
//                     alt={event.name}
//                     className="card-img-top"
//                 />
//                 <div className="card-body">
//                     <h5 className="card-title">{event.name}</h5>
//                     {/* <p className="card-text">{event.startdate}</p> */}
//                     <p className="card-text">
//                         {new Date(event.startdate).toLocaleDateString('en-US', {
//                             weekday: 'long', // e.g., Monday
//                             month: 'long',   // e.g., January
//                             day: 'numeric',  // e.g., 1
//                             year: 'numeric', // e.g., 2023
//                         })}
//                     </p>
//                     <p className="card-text">{event.type}</p>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default EventCard;


// import React from "react";
// import { useNavigate, useParams } from "react-router-dom";

// const EventCard = ({ eventId }) => {
//     const { tenant } = useParams();
//     const navigate = useNavigate();

//     const handleCardClick = () => {
//         navigate(`/${tenant}/eventDetail`, { state: { eventId } });
//     };

//     const imageUrl = eventId.image 
//         ? `http://localhost:5000/api/events/${eventId.tenantid}/${eventId}/events/${eventId.image}`
//         : '../assets/user-icon.png';

//     return (
//         <div className="member-card" style={{ transform: 'scale(.65)' }}>
//             <div className="card" onClick={handleCardClick} style={{ cursor: "pointer" }}>
//                 <img
//                     src={`http://localhost:5000${eventId.image}`} alt={eventId.name} onError={(e) => e.target.src = "/public/event.png"}
//                     className="card-img-top"
//                 />
//                 <div className="card-body">
//                     <h5 className="card-title">{eventId.name}</h5>
//                     <p className="card-text">{eventId.description}</p>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default EventCard;

import React from "react";
import { useNavigate, useParams } from "react-router-dom";

const EventCard = ({ event }) => {
    const { tenant } = useParams();
    const navigate = useNavigate();

    const baseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:5000';

    if (!event || !event.id) {
        console.error("Event is undefined");
        return <div className="event-card-error">Error: Event data missing</div>;
    }

    const handleCardClick = () => {
        navigate(`/${tenant}/${event.id}/eventDetail`, { state: { event } });
    };

    const filePath =
    event.files && event.files.length > 0 && event.files[0].path;
  const eventImage = filePath
    ? filePath.startsWith("http")
      ? filePath
      : `${baseUrl}${filePath}`
    : "/event.png";

    return (
        <div className="member-card" style={{ transform: 'scale(.65)' }}>
        <div className="card" onClick={handleCardClick} style={{ cursor: "pointer" }}>
            <img
                src={eventImage}
                alt={event.name || "Event"}
                className="card-img-top"
                onError={(e) => (e.target.src = "/event.png")} // Fallback if image fails
            />
            <div className="card-body">
                <h5>{event.name || "Unnamed Event"}</h5>
                <p>
                    {event.startdate
                        ? new Date(event.startdate).toLocaleDateString("en-US", {
                              weekday: "long",
                              month: "long",
                              day: "numeric",
                              year: "numeric",
                          })
                        : "Date not available"}
                </p>
                <p>{event.type || "No type specified"}</p>
            </div>
        </div>
        </div>
        
    );
};

export default EventCard;

