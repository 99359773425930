
// import React from "react";
// import { Routes, Route, useParams } from "react-router-dom";
// import Sidebar from "./Sidebar";
// import MemberList from "./member/MemberList";
// import MemberAdd from "./member/MemberAdd";
// import Dashboard from "./Dashboard";
// import MemberDetail from "./member/MemberDetail";
// import EventList from "./event/EventList";
// import EventAdd from "./event/EventAdd";
// import Event from "./event/Event";
// import EventDetail from "./event/EventDetail";
// import EventItemAdd from "./event/EventItemAdd";
// import EventItemDetail from "./event/EventItemDetail";
// import AppHeader from "./AppHeader";
// import TaskAdd from "./event/TaskAdd";
// import EventHub from "./event/EventHub";
// import EventAction from "./event/EventAction";

// const TenantRoutes = () => {
//     const { tenant, eventId } = useParams(); // Get tenant ID from URL

//     return (
//         <div className="tenant-page">
//             <div>
//                 <AppHeader/>
//                 <Sidebar tenantId={tenant} />
//             </div>
//             <div className="container">
//                 <div className="tenant-content">
//                     <Routes>
//                         <Route path="members" element={<MemberList tenantId={tenant} />} />
//                         <Route path="memberAdd" element={<MemberAdd tenantId={tenant} />} />
//                         <Route path="memberDetail" element={<MemberDetail tenantId={tenant} />} />

//                         <Route path="events" element={<EventList tenantId={tenant} />} />
//                         <Route path="eventAdd" element={<EventAdd tenantId={tenant} />} />
//                         <Route path=":eventId/event" element={<Event tenantId={tenant} />} />
//                         <Route path=":eventId/eventDetail" element={<EventDetail tenantId={tenant} />} />
//                         <Route path=":eventId/eventItemAdd" element={<EventItemAdd tenantId={tenant} />} />
//                         <Route path=":eventId/:itemId/eventItemDetail" element={<EventItemDetail tenantId={tenant} />} />
//                         <Route path=":eventId/task" element={<TaskAdd tenantId={tenant} />} />
//                         <Route path="eventHub" element={<EventHub tenantId={tenant} />} />
//                         <Route path=":eventId/:itemId/eventAction" element={<EventAction tenantId={tenant} />} />

//                         <Route path="dashboard" element={<Dashboard tenantId={tenant} />} />
//                     </Routes>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default TenantRoutes;


import React, { useState } from "react";
import { Routes, Route, useParams, useLocation } from "react-router-dom";
import Sidebar from "./Sidebar";
import MemberList from "./member/MemberList";
import MemberAdd from "./member/MemberAdd";
import Dashboard from "./Dashboard";
import MemberDetail from "./member/MemberDetail";
import EventList from "./event/EventList";
import EventAdd from "./event/EventAdd";
import Event from "./event/Event";
import EventDetail from "./event/EventDetail";
import EventItemAdd from "./event/EventItemAdd";
import EventItemDetail from "./event/EventItemDetail";
import AppHeader from "./AppHeader";
import TaskAdd from "./event/TaskAdd";
import EventHub from "./event/EventHub";
import EventAction from "./event/EventAction";
import UserAdd from "./tenant/UserAdd";
import Integrations from "./Integrations";

const TenantRoutes = () => {
  const { tenant } = useParams(); // Get tenant ID from URL
  const location = useLocation();
  // Check if the current path includes "eventAction"
  const hideHeaderAndSidebar = location.pathname.includes("eventAction");

  // State to track whether the Sidebar is open
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  // Function to toggle the Sidebar's open/closed state
  const toggleSidebar = () => setIsSidebarOpen(prev => !prev);

  return (
    <div className="tenant-page">
      {!hideHeaderAndSidebar && (
        <div>
          <AppHeader />
          <Sidebar tenantId={tenant} />
        </div>
      )}
      <div className="container">
        <div className="tenant-content">
          <Routes>
            <Route path="integrations" element={<Integrations tenantId={tenant} />} />
            <Route path="members" element={<MemberList tenantId={tenant} />} />
            <Route path="memberAdd" element={<MemberAdd tenantId={tenant} />} />
            <Route path="memberDetail" element={<MemberDetail tenantId={tenant} />} />
            <Route path="userAdd" element={<UserAdd tenantId={tenant} />} />
            <Route path="events" element={<EventList tenantId={tenant} />} />
            <Route path="eventAdd" element={<EventAdd tenantId={tenant} />} />
            <Route path=":eventId/event" element={<Event tenantId={tenant} />} />
            <Route path=":eventId/eventDetail" element={<EventDetail tenantId={tenant} />} />
            <Route path=":eventId/eventItemAdd" element={<EventItemAdd tenantId={tenant} />} />
            <Route path=":eventId/:itemId/eventItemDetail" element={<EventItemDetail tenantId={tenant} />} />
            <Route path=":eventId/task" element={<TaskAdd tenantId={tenant} />} />
            <Route path="eventHub" element={<EventHub tenantId={tenant} />} />
            <Route path=":eventId/:itemId/eventAction" element={<EventAction tenantId={tenant} />} />
            <Route path="dashboard" element={<Dashboard tenantId={tenant} />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default TenantRoutes;
