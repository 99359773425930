import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import MemberCard from './MemberCard';

const MemberList = () => {
    const [members, setMembers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { tenant } = useParams(); // Get tenant ID from the URL

    const baseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:5000';
    
    useEffect(() => {
        const fetchMembers = async () => {
            try {
                const response = await axios.get(`${baseUrl}/api/members/${tenant}/members`);
                
                console.error("API Response:", response.data); // Debugging step
                
                if (response.data.success && Array.isArray(response.data.members)) {
                    setMembers(response.data.members);
                } else {
                    console.error("Unexpected API response:", response.data);
                    setMembers([]); // Ensure it's always an array
                    setError(response.data.message || "No members found.");
                }
            } catch (err) {
                console.error("Error fetching members:", err);
                setError("Failed to fetch members. Server may be down.");
            } finally {
                setLoading(false);
            }
        };
    
        fetchMembers();
    }, [tenant]);
    

    if (loading) return <p>Loading members...</p>;
    // if (error) return <p>Error: {error}</p>;
    if (error) return <p style={{ color: 'red' }}>{error}</p>;

    return (
        <div className="container">
            <div className="member-list" style={{ maxWidth: "100vw" }}>
                {/* {members.length > 0 ? (
                    members.map((member, id) => ( */}
                    {/* {members && members.length > 0 ? ( */}
                    {Array.isArray(members) && members.length > 0 ? (
                        members.map((member, id) => (
                        <MemberCard member={member} key={member.email} />
                    ))
                ) : (
                    <p>No members found for this tenant.</p>
                )}
            </div>
        </div>
    );
};

export default MemberList;




