
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

function Integrations() {
    const [companyname, setCompanyName] = useState('');
    const [domain, setDomain] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [username, setUsername] = useState('');
    const [firstname, setFirstName] = useState('');
    const [lastname, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [registerStatus, setRegisterStatus] = useState('');

    const navigate = useNavigate();
    const { tenant } = useParams();

    const baseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:5000';

    const registerowner = async (e) => {
        e.preventDefault();
    
        try {
                const response = await axios.post(`${baseUrl}/api/tenants/registerowner`, {
                companyname,
                domain,
                username,
                email,
                phone,
                password,
                firstname,
                lastname,
            });
    
            if (response.status === 201) {
                setRegisterStatus('ACCOUNT CREATED SUCCESSFULLY');
                setTimeout(() => navigate('/ownerlogin'), 0);
            } else if (response.data.message) {
                setRegisterStatus(response.data.message);
            }
        } catch (error) {
            setRegisterStatus('An error occurred during registration.');
            console.error('Error during registration:', error);
        }
    };

    const handleDashboard = () => {
        navigate(`/${tenant}/dashboard`);
      };
    
    return (
        <div className="owner-add-container">
            <div className="card">
                <h4 className="card-title">Integrations</h4>
                <div className="card-body">
                    <form className="form">
                        <button type="submit" className="btn btn-primary">
                            Instagram
                        </button>
                        <button onClick={() => navigate(-1)} className="btn btn-secondary">
                            Facebook
                        </button>
                        <button type="submit" className="btn btn-primary">
                            Twitter
                        </button>
                        <button onClick={() => navigate(-1)} className="btn btn-secondary">
                            Linkedin
                        </button>
                        <button type="submit" className="btn btn-primary">
                            Empty
                        </button>
                        <button onClick={() => navigate(-1)} className="btn btn-secondary">
                            Empty
                        </button>
                        <button type="submit" className="btn btn-primary">
                            Empty
                        </button>
                        <button onClick={handleDashboard} className="btn btn-secondary">
                            Back
                        </button>
                        {registerStatus && <p className="status-message">{registerStatus}</p>}
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Integrations;

