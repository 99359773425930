// import React, { useState, useEffect } from "react";
// import { useNavigate, useParams, useLocation } from "react-router-dom";
// import axios from "axios";

// const EventAction = () => {
//     const { tenant, eventId, itemId } = useParams();
//     const [name, setName] = useState("");
//     const [email, setEmail] = useState("");
//     const [phone, setPhone] = useState("");
//     const [images, setImages] = useState([]);
//     const [attributes, setAttributes] = useState([]);
//     const navigate = useNavigate();

//     useEffect(() => {
//         const fetchItemData = async () => {
//             try {
//                 const response = await axios.get(`http://localhost:5000/api/items/${itemId}/details`);
//                 if (response.data.success) {
//                     setImages(response.data.images);
//                     setAttributes(response.data.attributes);
//                 }
//             } catch (error) {
//                 console.error("Error fetching event item details:", error);
//             }
//         };
//         fetchItemData();
//     }, [itemId]);

//     const handleSubmit = async () => {
//         const userData = { name, email, phone, itemId };
//         await axios.post(`http://localhost:5000/api/eventuser`, userData);
//         alert("Registered successfully!");
//     };

//     return (
//         <div className="event-action">
//             {images.map((img, index) => (
//                 <img key={index} src={img} alt="Thumbnail" className="thumbnail" />
//             ))}
//             {attributes.map(attr => (
//                 <select key={attr.name} className="form-control">
//                     {attr.values.map(value => (
//                         <option key={value}>{value}</option>
//                     ))}
//                 </select>
//             ))}
//             <div className="form-group">
//                 <label htmlFor="name">Name:</label>
//                 <input
//                     type="text"
//                     name="name"
//                     value={name}
//                     onChange={(e) => setName(e.target.value)}
//                     placeholder="Enter your name"
//                     required
//                 />
//             </div>
//             <div className="form-group">
//                 <label htmlFor="email">Email:</label>
//                 <input
//                     type="text"
//                     name="email"
//                     value={email}
//                     onChange={(e) => setEmail(e.target.value)}
//                     placeholder="Enter email address"
//                     required
//                 />
//             </div>
//             <div className="form-group">
//                 <label htmlFor="phone">Phone:</label>
//                 <input
//                     type="text"
//                     name="phone"
//                     value={phone}
//                     onChange={(e) => setPhone(e.target.value)}
//                     placeholder="Enter phone number"
//                     required
//                 />
//             </div>
//             <button  onClick={() => navigate(-1)} className="btn btn-secondary">
//                 Cancel
//             </button>
//             <button  onClick={handleSubmit} type="submit" className="btn btn-secondary me-2">
//                 Submit
//             </button>
//         </div>
        
//     );
// };

// export default EventAction;

import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const EventAction = () => {
  const { tenant, eventId, itemId } = useParams();
  const navigate = useNavigate();

  // States for event item details
  const [itemName, setItemName] = useState("");
  const [itemDescription, setItemDescription] = useState("");
  const [itemType, setItemType] = useState("default");
  const [topSize, setTopSize] = useState([]);
  const [bottomSize, setBottomSize] = useState([]);
  const [shoeSize, setShoeSize] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [images, setImages] = useState([]);

  // States for user registration form
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const baseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:5000';

  useEffect(() => {
    const fetchItemData = async () => {
      try {
        // Fetch both the event item details and associated images
        const [itemRes, imagesRes] = await Promise.all([
          axios.get(`${baseUrl}/api/${tenant}/events/${eventId}/items/${itemId}`),
          axios.get(`${baseUrl}/api/${tenant}/events/${eventId}/items/${itemId}/images`)
        ]);

        if (itemRes.data.success) {
          const itemData = itemRes.data.item || {};
          setItemName(itemData.name || "");
          setItemDescription(itemData.description || "");
          setItemType(itemData.type || "default");

          if (itemData.attributes) {
            // Extract specific attribute arrays if available
            const tops = itemData.attributes
              .filter(attr => attr.attributename === "top_size")
              .map(attr => attr.attributevalue);
            const bottoms = itemData.attributes
              .filter(attr => attr.attributename === "bottom_size")
              .map(attr => attr.attributevalue);
            const shoes = itemData.attributes
              .filter(attr => attr.attributename === "shoe_size")
              .map(attr => attr.attributevalue);

            setTopSize(tops);
            setBottomSize(bottoms);
            setShoeSize(shoes);
            // Keep the complete attributes array for default rendering
            setAttributes(itemData.attributes);
          }
        }
        if (imagesRes.data.success) {
          setImages(imagesRes.data.images);
        }
      } catch (error) {
        console.error("Error fetching event item details:", error);
      }
    };

    if (tenant && eventId && itemId) {
      fetchItemData();
    }
  }, [tenant, eventId, itemId]);

  const handleSubmit = async () => {
    const userData = { name: userName, email, phone, eventId, itemId };
    try {
      await axios.post(`${baseUrl}/api/${tenant}/registereventuser`, userData);
      alert("Registered successfully!");
      window.close();
    } catch (error) {
      console.error("Registration error:", error);
      alert("Registration failed");
    }
  };

return (
    <div className="col=md-4">
        <div className="card">
            <div className="card-body">
            <h4>{itemName}</h4>
            {/* Image Card: display the first image */}
            <div className="card" style={{ width: "18rem", marginBottom: "1rem" }}>
              {images.length > 0 ? (
                <img
                  src={images[0].path || images[0]}
                  alt="Event Item"
                  className="card-img-top"
                  onError={(e) => e.target.src = "/event.png"}
                />
              ) : (
                <p>No image available</p>
              )}
            </div>
            <h4>{itemDescription}</h4>
            {/* Dynamic Attribute Dropdowns */}
            <div className="attributes" style={{ marginTop: "1rem" }}>
              {itemType === "clothing" && (
                <>
                  {topSize.length > 0 && (
                    <>
                      <label>Shirt Size:</label>
                      <select className="form-control" style={{ marginBottom: "0.5rem" }}>
                        {topSize.map((size, idx) => (
                          <option key={idx} value={size}>
                            {size}
                          </option>
                        ))}
                      </select>
                    </>
                  )}
                  {bottomSize.length > 0 && (
                    <>
                      <label>Pant Size:</label>
                      <select className="form-control">
                        {bottomSize.map((size, idx) => (
                          <option key={idx} value={size}>
                            {size}
                          </option>
                        ))}
                      </select>
                    </>
                  )}
                </>
              )}
              {itemType === "shoes" && shoeSize.length > 0 && (
                <>
                  <label>Shoe Size:</label>
                  <select className="form-control">
                    {shoeSize.map((size, idx) => (
                      <option key={idx} value={size}>
                        {size}
                      </option>
                    ))}
                  </select>
                </>
              )}
              {itemType === "default" && attributes.length > 0 && (
                <div>
                  {attributes.map(attr => (
                    <div key={attr.attributename}>
                      <strong>{attr.attributename}:</strong> {attr.attributevalue}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
                <form onSubmit={handleSubmit}>
                    <div className="form-group" style={{ marginBottom: "1rem" }}>
                    <label htmlFor="userName">Name:</label>
                    <input
                        type="text"
                        id="userName"
                        name="userName"
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                        placeholder="Enter your name"
                        required
                        className="form-control"
                    />
                    </div>
                    <div className="form-group" style={{ marginBottom: "1rem" }}>
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter your email"
                        required
                        className="form-control"
                    />
                    </div>
                    <div className="form-group" style={{ marginBottom: "1rem" }}>
                    <label htmlFor="phone">Phone:</label>
                    <input
                        type="text"
                        id="phone"
                        name="phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        placeholder="Enter your phone number"
                        required
                        className="form-control"
                    />
                    </div>
                    <button onClick={() => window.close()} className="btn btn-secondary" style={{ marginRight: "1rem" }}>
                    Cancel
                    </button>
                    <button onClick={handleSubmit} type="button" className="btn btn-secondary">
                    Submit
                    </button>
                </form>
            </div>
        </div>
     // </div>
  );
};

export default EventAction;

