
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import axios from "axios";

const CommentBox = ({ placeholder = "Type a comment...", onCommentChange, clearComment  }) => {
  const [comment, setComment] = useState("");
  const [users, setUsers] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const { tenant } = useParams();

  const baseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:5000';

  useEffect(() => {
    axios.get(`${baseUrl}/api/${tenant}/users`)
      .then((response) => {
        setUsers(response.data.users || []);
      })
      .catch((error) => {
        console.error("❌ Error fetching users:", error);
        setUsers([]);
      });
  }, [tenant]);
  

  const handleInputChange = (e) => {
    const input = e.target.value;
    setComment(input);
    onCommentChange(input);

    if (input.includes("@")) {
      const searchText = input.split("@").pop().toLowerCase();
      setSuggestions(users.filter((user) => user.username.toLowerCase().includes(searchText)));
    } else {
      setSuggestions([]);
    }
  };

  useEffect(() => {
    if (clearComment) {
      setComment(""); // ✅ Clear comment on successful task submission
    }
  }, [clearComment]);

  return (
    <div className="comment-box">
      <textarea 
      value={comment} 
      onChange={handleInputChange} 
      placeholder={placeholder}
      style={{ color: "black" }}
      />

      {suggestions.length > 0 && (
        <ul className="suggestions">
          {suggestions.map((user) => (
            <li key={user.id} onClick={() => setComment((prev) => prev.replace(/@\S*$/, `@${user.username} `))}>
              @{user.username}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CommentBox;
