import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const Event = () => {
    const { tenant, eventId } = useParams();
    const navigate = useNavigate();
    const [event, setEvent] = useState(null);
    const [items, setItems] = useState([]);

    const baseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:5000';

    useEffect(() => {
        const fetchEvent = async () => {
            try {
                const response = await axios.get(
                    `${baseUrl}/api/${tenant}/events/${eventId}`
                );
                setEvent(response.data.event);
            } catch (error) {
                console.error('Error fetching event:', error);
            }
        };

        const fetchEventItems = async () => {
            try {
                const response = await axios.get(
                    `${baseUrl}/api/${tenant}/event/${eventId}/item`
                );
                setItems(response.data.items);
            } catch (error) {
                console.error('Error fetching event items:', error);
            }
        };

        fetchEvent();
        fetchEventItems();
    }, [tenant, eventId]);

    const handleItemClick = (item) => {
        navigate(`/${tenant}/eventItem`, { state: { item } });
    };

    if (!event) {
        return <p>Loading event...</p>;
    }

    return (
        <div className="event">
            <h1>{event.name}</h1>
            <p>{event.description}</p>
            <p>Type: {event.type}</p>
            <p>Start Date: {new Date(event.startdate).toLocaleDateString()}</p>
            <p>End Date: {new Date(event.enddate).toLocaleDateString()}</p>
            <div className="event-items">
                {items.map((item) => (
                    <div
                        key={item.id}
                        className="event-item-card"
                        onClick={() => handleItemClick(item)}
                    >
                        <img src={item.image || 'default-image-url.jpg'} alt={item.name} />
                        <h4>{item.name}</h4>
                        <p>{item.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Event;
