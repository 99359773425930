
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function OwnerAdd() {
    const [companyname, setCompanyName] = useState('');
    const [domain, setDomain] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [username, setUsername] = useState('');
    const [firstname, setFirstName] = useState('');
    const [lastname, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [registerStatus, setRegisterStatus] = useState('');

    const navigate = useNavigate();

    const baseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:5000';

    const registerowner = async (e) => {
        e.preventDefault();
    
        try {
                const response = await axios.post(`${baseUrl}/api/tenants/registerowner`, {
                companyname,
                domain,
                username,
                email,
                phone,
                password,
                firstname,
                lastname,
            });
    
            if (response.status === 201) {
                setRegisterStatus('ACCOUNT CREATED SUCCESSFULLY');
                setTimeout(() => navigate('/ownerlogin'), 0);
            } else if (response.data.message) {
                setRegisterStatus(response.data.message);
            }
        } catch (error) {
            setRegisterStatus('An error occurred during registration.');
            console.error('Error during registration:', error);
        }
    };
    
    return (
        <div className="owner-add-container">
            <div className="card">
                <h4 className="card-title">Create Account</h4>
                <div className="card-body">
                    <form onSubmit={registerowner} className="form">
                        <div className="form-group">
                            <label htmlFor="companyname">Company Name</label>
                            <input
                                type="text"
                                id="companyname"
                                value={companyname}
                                onChange={(e) => setCompanyName(e.target.value)}
                                placeholder="Company Name"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="domain">Domain</label>
                            <input
                                type="text"
                                id="domain"
                                value={domain}
                                onChange={(e) => setDomain(e.target.value)}
                                placeholder="Company Domain"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Email Address"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="phone">Phone</label>
                            <input
                                type="text"
                                id="phone"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                placeholder="Phone Number"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="username">Username</label>
                            <input
                                type="text"
                                id="username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                placeholder="Username"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="firstname">First Name</label>
                            <input
                                type="text"
                                id="firstname"
                                value={firstname}
                                onChange={(e) => setFirstName(e.target.value)}
                                placeholder="First Name"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="lastname">Last Name</label>
                            <input
                                type="text"
                                id="lastname"
                                value={lastname}
                                onChange={(e) => setLastName(e.target.value)}
                                placeholder="Last Name"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Password</label>
                            <input
                                type="password"
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Password"
                                required
                            />
                        </div>
                        <button type="submit" className="btn btn-primary">
                            Create Owner
                        </button>
                        <button onClick={() => navigate(-1)} className="btn btn-secondary">
                            Back
                        </button>
                        {registerStatus && <p className="status-message">{registerStatus}</p>}
                    </form>
                </div>
            </div>
        </div>
    );
}

export default OwnerAdd;

