
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

function TenantAdd() {
    const location = useLocation();
    const navigate = useNavigate();

    const baseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:5000';
    
    const [ownerData, setOwnerData] = useState(() => {
        const stateData = location.state;
        const localData = localStorage.getItem('ownerData');
        return stateData || (localData ? JSON.parse(localData) : null);
    });

    const [formState, setFormState] = useState({
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        logo: null,
        waiver: null,
    });
    const [registerStatus, setRegisterStatus] = useState('');

    useEffect(() => {
      if (!ownerData || !ownerData.id) {
          setRegisterStatus('Owner ID is missing. Please log in again.');
          navigate('/ownerlogin'); // Redirect back to login if ownerData is missing
      }
  }, [ownerData, navigate]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormState((prev) => ({ ...prev, [name]: value }));
    };

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        setFormState((prev) => ({ ...prev, [name]: files[0] }));
    };

    const registerTenant = async (e) => {
        e.preventDefault();

        if (!ownerData?.id) {
          setRegisterStatus('Owner ID is missing. Please log in again.');
          return;
      }

        const formData = new FormData();
        formData.append('ownerid', ownerData.id);
        formData.append('companyname', ownerData.companyname);
        formData.append('domain', ownerData.domain);
        formData.append('username', ownerData.username);
        formData.append('password', ownerData.password);
        formData.append('email', ownerData.email);
        formData.append('firstname', ownerData.firstname);
        formData.append('lastname', ownerData.lastname);
        formData.append('phone', ownerData.phone);
        formData.append('address1', formState.address1);
        formData.append('address2', formState.address2);
        formData.append('city', formState.city);
        formData.append('state', formState.state);
        formData.append('zip', formState.zip);
        if (formState.logo) {
            formData.append('logo', formState.logo);
        }
        if (formState.waiver) {
            formData.append('waiver', formState.waiver);
        }

        try {
            const response = await axios.post(`${baseUrl}/api/tenants/registertenant`, formData);
            console.log('Tenant registered:', response.data);
            setTimeout(() => navigate('/login'), 0);
            setRegisterStatus(response.data.message || 'Tenant registered successfully!');
        } catch (error) {
            console.error('Error registering tenant:', error.response?.data || error.message);
            setRegisterStatus(error.response?.data?.message || 'Failed to register tenant.');
        }
    };

    return (
        <div className="col-md-4">
            <div className="card">
                <div className="card-body">
                    <h4>Create Tenant</h4>
                    <form onSubmit={registerTenant}>
                    <p style={{ display: "none" }}>Owner ID: {ownerData.id}</p>
                    <p style={{ display: "none" }}>Company Name: {ownerData.companyname}</p>
                    <p style={{ display: "none" }}>Domain: {ownerData.domain}</p>
                    <p style={{ display: "none" }}>Username: {ownerData.username}</p>
                    <p style={{ display: "none" }}>Password: {ownerData.password}</p>
                    <p style={{ display: "none" }}>First Name: {ownerData.firstname}</p>
                    <p style={{ display: "none" }}>Last Name: {ownerData.lastname}</p>
                    <p style={{ display: "none" }}>Email: {ownerData.email}</p>
                    <p style={{ display: "none" }}>Phone: {ownerData.phone}</p>
                        <div className="form-group">
                            <label htmlFor="address1">Address 1:</label>
                            <input
                                className="textInput"
                                type="text"
                                name="address1"
                                value={formState.address1}
                                onChange={handleInputChange}
                                placeholder="Address 1"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="address2">Address 2:</label>
                            <input
                                className="textInput"
                                type="text"
                                name="address2"
                                value={formState.address2}
                                onChange={handleInputChange}
                                placeholder="Address 2"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="city">City:</label>
                            <input
                                className="textInput"
                                type="text"
                                name="city"
                                value={formState.city}
                                onChange={handleInputChange}
                                placeholder="City"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="state">State:</label>
                            <input
                                className="textInput"
                                type="text"
                                name="state"
                                value={formState.state}
                                onChange={handleInputChange}
                                placeholder="State"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="zip">Zip:</label>
                            <input
                                className="textInput"
                                type="text"
                                name="zip"
                                value={formState.zip}
                                onChange={handleInputChange}
                                placeholder="Zip"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="logo">Upload Logo:</label>
                            <input type="file" name="logo" onChange={handleFileChange} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="waiver">Upload Waiver:</label>
                            <input type="file" name="waiver" onChange={handleFileChange} />
                        </div>
                        <button className="btn btn-primary" type="submit">
                            Register Tenant
                        </button>
                    </form>
                    {registerStatus && <p>{registerStatus}</p>}
                </div>
            </div>
        </div>
    );
}

export default TenantAdd;

