
// import React, { useState, useEffect } from 'react';
// import { useNavigate, useParams } from 'react-router-dom';
// import axios from 'axios';
// import Calendar from './media/Calendar'; // Import the Calendar component

// function Dashboard() {
//   const [events, setEvents] = useState([]);
//   const { tenant } = useParams(); // Retrieve tenantId from the URL
//   const navigate = useNavigate();
//   const [tenantData, setTenantData] = useState(null);
//   const [error, setError] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

//   useEffect(() => {
//     const fetchEvents = async () => {
//       try {
//           const url = `http://localhost:5000/api/${tenant}/events`;
//           console.log('Fetching events from:', url); // Debugging log
//           const response = await axios.get(url);
  
//           if (response.data.success) {
//               setEvents(response.data.data);
//           } else {
//               setError(response.data.message || 'Failed to fetch events.');
//           }
//       } catch (err) {
//           console.error('Error fetching events:', err);
//           setError('Failed to fetch events. Please try again later.');
//       } finally {
//           setLoading(false);
//       }
//   };

//     fetchEvents();
// }, [tenant]);

//   return (
//     <div className="container">
//         <h2>Dashboard</h2>
//         <th></th>
//          <table></table>
//         <div className="calendar-grid">
//             {weekdays.map((day, index) => (
//                 <div key={index} className="calendar-day">
//                     {day}
//                 </div>
//             ))}
//         </div>
//     </div>

// );
// }

// export default Dashboard;

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { format } from 'date-fns';

function Dashboard() {
  const [events, setEvents] = useState([]);
  const { tenant } = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const baseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:5000';

  // Fetch events (if needed for other purposes)
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const url = `${baseUrl}/api/${tenant}/events`;
        const response = await axios.get(url);
        if (response.data.success) {
          setEvents(response.data.data);
        } else {
          setError(response.data.message || 'Failed to fetch events.');
        }
      } catch (err) {
        console.error('Error fetching events:', err);
        setError('Failed to fetch events. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, [tenant]);

  // Generate an array of 7 days starting from today
  const getDays = () => {
    const days = [];
    const today = new Date();
    for (let i = 0; i < 7; i++) {
      const day = new Date();
      day.setDate(today.getDate() + i);
      days.push(day);
    }
    return days;
  };

  const days = getDays();

  // When a day is clicked, navigate to the Day component route.
  // Here we pass the date formatted as yyyy-MM-dd.
  const handleDayClick = (day) => {
    const dayStr = format(day, 'yyyy-MM-dd');
    navigate(`/day/${dayStr}`);
  };

  // Separate the first day from the remaining days.
  const currentDay = days[0];
  const remainingDays = days.slice(1);

  // Group the remaining days into rows with 2 cards per row.
  const rows = [];
  for (let i = 0; i < remainingDays.length; i += 2) {
    rows.push(remainingDays.slice(i, i + 2));
  }

//   return (
//     <div className="container">
//       <h2>Dashboard</h2>
//       {loading && <p>Loading...</p>}
//       {error && <p>{error}</p>}
//       <table style={{ width: '100%', borderCollapse: 'collapse' }}>
//         <tbody>
//           {/* Row for the current day */}
//           <tr>
//             <td colSpan="2" style={{ padding: '10px' }}>
//               <div
//                 className="day-card current-day-card"
//                 style={{
//                   cursor: 'pointer',
//                   border: '1px solid #ccc',
//                   borderRadius: '8px',
//                   padding: '20px',
//                   textAlign: 'center',
//                   height: '30vh', // Approx. top third of the screen
//                   display: 'flex',
//                   flexDirection: 'column',
//                   justifyContent: 'center',
//                   backgroundColor: '#f0f0f0'
//                 }}
//                 onClick={() => handleDayClick(currentDay)}
//               >
//                 <h3>{format(currentDay, 'EEEE')}</h3>
//                 <p>{format(currentDay, 'MMMM do, yyyy')}</p>
//               </div>
//             </td>
//           </tr>
//           {/* Rows for the remaining days */}
//           {rows.map((row, rowIndex) => (
//             <tr key={rowIndex}>
//               {row.map((day, index) => (
//                 <td key={index} style={{ padding: '10px' }}>
//                   <div
//                     className="day-card"
//                     style={{
//                       cursor: 'pointer',
//                       border: '1px solid #ccc',
//                       borderRadius: '8px',
//                       padding: '20px',
//                       textAlign: 'center',
//                       height: '20vh',
//                       backgroundColor: '#fff'
//                     }}
//                     onClick={() => handleDayClick(day)}
//                   >
//                     <h4>{format(day, 'EEEE')}</h4>
//                     <p>{format(day, 'MMMM do, yyyy')}</p>
//                   </div>
//                 </td>
//               ))}
//               {row.length < 2 && <td></td>}
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// return (
//     <div className="main-content">
//     <div className="col-md-1">
//       <div className="card">
//         <h4>Dashboard</h4>
//         <div className="card-body">
//           <form>
//             {loading && <p>Loading...</p>}
//             {error && <p>{error}</p>}
//             <table style={{ width: '100%', borderCollapse: 'collapse' }}>
//               <tbody>
//                 {/* Row for the current day */}
//                 <tr>
//                   <td colSpan="2" style={{ padding: '5px' }}>
//                     <div
//                       className="day-card current-day-card"
//                       style={{
//                         cursor: 'pointer',
//                         border: '1px solid #ccc',
//                         borderRadius: '8px',
//                         padding: '20px',
//                         textAlign: 'center',
//                         height: '30vh', // Approx. top third of the screen
//                         display: 'flex',
//                         flexDirection: 'column',
//                         justifyContent: 'center',
//                         backgroundColor: '#f0f0f0'
//                       }}
//                       onClick={() => handleDayClick(currentDay)}
//                     >
//                       <h3>{format(currentDay, 'EEEE')}</h3>
//                       <p>{format(currentDay, 'MMMM do, yyyy')}</p>
//                     </div>
//                   </td>
//                 </tr>
//                 {/* Rows for the remaining days */}
//                 {rows.map((row, rowIndex) => (
//                   <tr key={rowIndex}>
//                     {row.map((day, index) => (
//                       <td key={index} style={{ padding: '10px' }}>
//                         <div
//                           className="day-card"
//                           style={{
//                             cursor: 'pointer',
//                             border: '1px solid #ccc',
//                             borderRadius: '8px',
//                             padding: '20px',
//                             textAlign: 'center',
//                             height: '20vh',
//                             backgroundColor: '#fff'
//                           }}
//                           onClick={() => handleDayClick(day)}
//                         >
//                           <h4>{format(day, 'EEEE')}</h4>
//                           <p>{format(day, 'MMMM do, yyyy')}</p>
//                         </div>
//                       </td>
//                     ))}
//                     {row.length < 2 && <td></td>}
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//             <button type="submit" className="btn btn-primary mt-3">
//               Create Account
//             </button>
//           </form>
//         </div>
//       </div>
//     </div>
//     </div>
//   );

return (
    <div className="dashboard-container">
      <h2>Dashboard</h2>
      {loading && <p>Loading...</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}

      <table className="day-table">
        <tbody>
          {/* Top row (current day) */}
          <tr>
            <td colSpan="2" className="day-cell">
              <div
                className="day-card current-day"
                onClick={() => handleDayClick(currentDay)}
              >
                <h3>{format(currentDay, 'EEEE')}</h3>
                <p>{format(currentDay, 'MMMM do, yyyy')}</p>
              </div>
            </td>
          </tr>

          {/* Bottom rows (2 cards per row) */}
          {rows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((day, index) => (
                <td key={index} className="day-cell">
                  <div
                    className="day-card remaining-day"
                    onClick={() => handleDayClick(day)}
                  >
                    <h4>{format(day, 'EEEE')}</h4>
                    <p>{format(day, 'MMMM do, yyyy')}</p>
                  </div>
                </td>
              ))}
              {/* If there's only one day in this row, add an empty cell to keep layout consistent */}
              {row.length < 2 && <td className="day-cell" />}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Dashboard;
